import moment from 'moment';

export function format(value, type) {
  if (value === "") {
    return "";
  }
  switch (type) {
    case 'time':
    case 'delta':
      return timeInSeconds(value);
    default:
      return value;
  }
}

export function timeInSeconds(seconds, places=3) {
  if (isNaN(seconds)) {
    return seconds;
  }

  seconds = parseFloat(seconds);

  const negate = seconds < 0 ? '-' : '';
  seconds = Math.abs(seconds);

  if (seconds < 60) {
    return `${negate}${seconds.toFixed(places)}`;
  }
  var minutes = Math.floor(seconds / 60);
  seconds = (seconds - (60 * minutes)).toFixed(places);

  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return `${negate}${minutes}:${seconds}`;
}

export function timeWithHours(seconds) {
  var hours = Math.floor(seconds / 3600);
  seconds -= (3600 * hours);
  var minutes = Math.floor(seconds / 60);
  seconds = Math.floor(seconds - (60 * minutes));
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
}

export function timestamp(unix) {
  return moment(unix * 1000).format("HH:mm:ss");
}

export function datetime(d) {
  return moment(d).format("YYYY-MM-DD HH:mm");
}

export function classNameFromCategory(cat) {
  if (cat && cat.replace) {
    return cat.replace(/[ ./]/g, "").toLowerCase();
  }
  return undefined;
}

export function pluralize(value, unit) {
  return `${value} ${unit}${value === 1 ? '' : 's'}`;
}
