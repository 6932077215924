import React from 'react';

import Panel from './Panel';

import { timeWithHours } from 'utils/formats';

const _MINUTE = 60;
const _HOUR = 60 * _MINUTE;
const _DAY = 24 * _HOUR;

export default class ServiceCountdown extends React.Component {

  constructor(props) {
    super(props);
    this.state = { seconds: -1 };
    this.updateTimer = this.updateTimer.bind(this);
    this.interval = null;
  }

  componentDidMount() {
    this.updateTimer();
    if (this.interval === null) {
      this.interval = setInterval(this.updateTimer, 1000);
    }
  }

  componentWillUnmount() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  }

  updateTimer() {
    if (this.props.service) {
      this.setState({ seconds: this.props.service.startTime - (Date.now() / 1000) });
    }
  }

  render() {
    const { seconds } = this.state;
    const { service } = this.props;

    if (!service || seconds < 0) {
      return <p>No events scheduled.</p>;
    }

    let displayString = "";

    const days = Math.floor(seconds / _DAY);

    if (days > 1) {
      displayString = `${days} days `;
    }
    else if (days === 1) {
      displayString = '1 day ';
    }

    displayString += timeWithHours(seconds - (days * _DAY));


    return (
      <Panel
        className={`service-panel service-panel-upcoming ${ service.hidden ? 'hidden' : '' }`}
        header={service.name}
      >
        <span className="service-description">Starts in {displayString}</span>
      </Panel>
    );

  }
}
