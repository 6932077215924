import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DocumentTitle from 'react-document-title';
import classnames from 'classnames';

import { APP_NAME } from 'config/settings';

import Clock from 'components/Clock';
import FlagStatusPanel from 'components/FlagStatusPanel';
import TimingTable from 'components/TimingTable';
import Messages from 'components/Messages';
import TrackData from 'components/TrackData';
import TrackDataFooter from 'components/TrackDataFooter';
import TimingColumnHider from '../components/TimingColumnHider';


export default class TimingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMessages: true };
    this.toggleMessages = this.toggleMessages.bind(this);
  }

  toggleMessages() {
    this.setState({ showMessages: !this.state.showMessages });
  }

  render() {
    const {
      session,
      service,
      cars,
      innerRef,
      messages,
      highlight,
      menu,
      pauseClocks,
      fontScale,
      isCompactMode,
      showAnimations,
      showBackgroundColours,
      showColumnsDialog,
      toggleColumnsDialog
    } = this.props;
    let remaining = null;
    if (session.lapsRemain !== undefined) {
      remaining = <div className="clock remaining">{session.lapsRemain} lap{session.lapsRemain === 1 ? "" : "s"} remaining</div>;
    }
    else if (session.timeRemain !== undefined) {
      remaining = (
        <Clock
          caption="remaining"
          className='remaining'
          countdown
          pause={pauseClocks || session.pauseClocks}
          seconds={session.timeRemain}
        />
      );
    }

    const messagesContainer = (
      <Fragment>
        <div className='messages-container'>
          <Messages
            disableHeader
            messages={messages}
          />
        </div>
        <TrackData
          dataset={session.trackData}
          spec={service.trackDataSpec}
        />
        <div className="trackdata-footer-float">
          <TrackDataFooter
            external={service.external}
            isHiddenService={!!service.hidden}
            messagesShowing={this.state.showMessages}
            source={service.source}
            toggleMessages={this.toggleMessages}
          />
        </div>
      </Fragment>
    );

    return (
      <DocumentTitle title={`${service.name} - ${APP_NAME}`}>
        <div
          className={classnames(
            'screen',
            'timing-screen',
            {
              'show-messages': this.state.showMessages,
              'compact': isCompactMode
            }
          )}
          ref={innerRef}
          style={{ fontSize: `${fontScale}rem` }}
        >
          <div className="timing-screen-header">
            {
              session.timeElapsed ?
                <Clock
                  caption="elapsed"
                  className='elapsed'
                  pause={pauseClocks || session.pauseClocks}
                  seconds={session.timeElapsed}
                />
                : null
            }
            <FlagStatusPanel
              flag={session.flagState}
              text={`${service.name} - ${service.description}`}
            />
            {remaining}
            {menu}
          </div>
          <div className="timing-table-container">
            <TimingColumnHider
              columnSpec={service.colSpec}
              isShowingDialog={showColumnsDialog}
              toggleDialog={toggleColumnsDialog}
            >
              <TimingTable
                cars={cars}
                columnSpec={service.colSpec}
                highlight={highlight}
                showAnimations={showAnimations}
                showBackgroundColours={showBackgroundColours}
              />
            </TimingColumnHider>
          </div>
          { messagesContainer }
        </div>
      </DocumentTitle>
    );
  }
}

TimingScreen.propTypes = {
  cars: PropTypes.arrayOf(PropTypes.array).isRequired,
  fontScale: PropTypes.number,
  highlight: PropTypes.arrayOf(PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])),
  innerRef: PropTypes.object,
  isCompactMode: PropTypes.bool,
  menu: PropTypes.node,
  messages: PropTypes.arrayOf(PropTypes.array).isRequired,
  pauseClocks: PropTypes.bool,
  service: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  showAnimations: PropTypes.bool,
  showBackgroundColours: PropTypes.bool,
  showColumnsDialog: PropTypes.bool,
  toggleColumnsDialog: PropTypes.func.isRequired
};

TimingScreen.defaultProps = {
  fontScale: 1
};
