import React from 'react';
import PropTypes from 'prop-types';

import { timeWithHours } from 'utils/formats';

export default class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.updateTimer = this.updateTimer.bind(this);
    this.state = { seconds: 0, refTime: Date.now(), delta: 0 };
    this.interval = null;
  }

  componentDidMount() {
    this.updateTimer();
    if (this.interval === null) {
      this.interval = setInterval(this.updateTimer, 1000);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pause !== this.props.pause) {
      if (this.props.pause === false && prevProps.pause === true) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(
          (s) => ({ refTime: Date.now() - (1000 * s.delta), delta: 0 })
        );
      }
    }
    if (prevProps.seconds !== this.props.seconds) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        () => ({ refTime: Date.now(), delta: 0 })
      );
    }
  }

  componentWillUnmount() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  }

  updateTimer() {
    if (this.props.seconds !== null) {
      const delta = this.props.pause ? this.state.delta : Math.floor((Date.now() - this.state.refTime) / 1000);
      if (this.props.countdown) {
        this.setState({ seconds: Math.max(this.props.seconds - delta, 0), delta: delta });
      }
      else {
        this.setState({ seconds: this.props.seconds + delta, delta: delta });
      }
    }
  }
  render() {
    return <div className={`clock ${this.props.className}`}>{timeWithHours(this.state.seconds)} {this.props.caption}</div>;
  }
}

Clock.propTypes = {
  caption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  className: PropTypes.string,
  countdown: PropTypes.bool,
  pause: PropTypes.bool,
  seconds: PropTypes.number
};
