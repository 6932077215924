import React from 'react';
import PropTypes from 'prop-types';

const FlagStatusPanel = ({ flag, text }) => (<div className={"flag-status flag-status-" + flag}>{text}</div>);

FlagStatusPanel.propTypes = {
  flag: PropTypes.string,
  text: PropTypes.string
};

export default FlagStatusPanel;
