import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

const ServiceHeading = ({ service }) => (
  <React.Fragment>
    {
      service.hidden ? <FontAwesome name='lock' /> : null
    }
    <span className='service-name'>
      {
        service.name
      }
    </span>
  </React.Fragment>
);

ServiceHeading.propTypes = {
  service: PropTypes.shape({
    hidden: PropTypes.bool,
    name: PropTypes.string.isRequired
  })
};

export default ServiceHeading;
