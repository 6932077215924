import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import LinkContainer from 'components/LinkContainer';

import { Row, Col } from 'reactstrap';
import Panel from './Panel';
import ServiceHeading from './ServiceHeading';
import SyndicationEntry from './SyndicationEntry';

const ServiceListEntry = ({ linkPart, service, preferServiceClass }) => {

  let to;

  if (!service.hidden && preferServiceClass && service.serviceClass) {
    to = `/s/${service.serviceClass}`;
  }
  else {
    to = `/${linkPart}/${service.uuid}`;
  }

  const classes = classNames(
    'service-panel',
    {
      'hidden': service.hidden,
      'external': !!service.external
    }
  );

  return (
    <Col
      md={4}
      sm={12}
    >
      <LinkContainer
        newWindow='electron'
        to={to}
      >
        <Panel
          className={classes}
          header={<ServiceHeading service={service} />}
        >
          <span className="service-description">{service.description}</span>
          {
            !!service.external ?
              <SyndicationEntry service={service} />
              : null
          }
        </Panel>
      </LinkContainer>
    </Col>
  );
};

ServiceListEntry.propTypes = {
  linkPart: PropTypes.string,
  preferServiceClass: PropTypes.bool,
  service: PropTypes.object.isRequired
};

const ServiceList = ({ services, linkPart, preferServiceClass }) => {
  if (!services.length) {
    return <p>No services available.</p>;
  }
  return (
    <Row className="service-list">
      {_(services).sortBy((s) => s.startTime).map((svc) => (
        <ServiceListEntry
          key={svc.uuid}
          linkPart={linkPart}
          preferServiceClass={preferServiceClass}
          service={svc}
        />
      )).value()}
    </Row>
  );
};

ServiceList.propTypes = {
  linkPart: PropTypes.string,
  preferServiceClass: PropTypes.bool,
  services: PropTypes.array
};

export default ServiceList;
