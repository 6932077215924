import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import withGracefulUnmount from 'components/withGracefulUnmount';

const SubscriptionError = (message) => ({
  message: message,
  name: 'SubscriptionError'
});

class SubscriptionManager extends React.Component {

  constructor(props) {
    super(props);
    this.state = { data: {}, sessionId: null };
    this.pendingSubscriptions = [];

    this.subscriptions = {};
    this.subscriptionCounts = {};
    this.sessionId = null;

    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
    this._purge = this._purge.bind(this);
  }

  componentDidUpdate() {
    const { session } = this.props;
    if (session) {
      if (session.id !== this.sessionId && session.id !== null) {
        console.debug("Session ID changed from", this.sessionId, "to", session.id);
        const priorSubscriptions = _.values(this.subscriptions);

        _.forEach(
          priorSubscriptions,
          (sub) => {
            session.subscribe(
              sub.topic,
              sub.handler,
              sub.options
            ).then(
              (newSub) => {
                this.subscriptions[sub] = newSub;
                console.debug(`Reestablished subscription to ${newSub.topic}`);
              }
            );
          }
        );
      }
      this.sessionId = session.id;

      while (this.pendingSubscriptions.length > 0) {
        const [sub, options] = this.pendingSubscriptions.pop();
        this._subscribeInSession(sub, session, options);
      }
    }
  }

  componentWillUnmount() {
    this._purge(true);
  }

  subscribe(sub, options={}) {
    this._subscribeInSession(sub, this.props.session, options);
  }

  _subscribeInSession(sub, session, options) {
    const [topic] = sub;

    let handler;
    if (sub.length === 3) {
      handler = this._makeHandler(sub[1], sub[2]);
    }
    else if (sub.length === 2) {
      handler = sub[1];
    }
    else {
      throw SubscriptionError('Subscriptions should either be [topic, messageClass, dataProp] or [topic, handlerFunction].');
    }

    if (!this.subscriptionCounts[sub]) {
      this.subscriptionCounts[sub] = 0;
    }

    const myOpts = {
      ...options,
      get_retained: true  // eslint-disable-line camelcase
    };

    if (session && session.isOpen) {
      this.subscriptionCounts[sub] += 1;
      if (!this.subscriptions[sub]) {


        session.subscribe(
          topic,
          handler,
          myOpts
        ).then(
          (newSub) => {
            this.subscriptions[sub] = newSub;
            console.debug(`Established subscription to ${newSub.topic} with options`, myOpts);
          }
        );
      }
    }
    else {
      this.pendingSubscriptions.push([sub, myOpts]);
    }
  }

  unsubscribe(sub) {
    if (this.subscriptionCounts[sub] > 0) {
      this.subscriptionCounts[sub] -= 1;
    }
    setTimeout(this._purge, 1000);
  }

  _makeHandler(messageClass, dataProp) {
    return (data) => {
      _(data).forEach((message) => {
        if (message.msgClass === messageClass) {
          this.setState({
            data: {
              ...this.state.data,
              [dataProp]: message.payload
            }
          });
        }
      });
    };
  }

  _purge(force=false) {
    const { session } = this.props;
    if (session) {
      _.forEach(
        this.subscriptionCounts,
        (count, sub) => {
          if (count <= 0 || force) {
            const abSub = this.subscriptions[sub];
            if (abSub && abSub.active) {
              try {
                session.unsubscribe(abSub).then(
                  () => {
                    console.debug(`Terminated subscription to ${abSub.topic}`);
                  }
                );
              } catch (err) {
                console.error("Error when trying to unsubscribe:", err);
              }
            }
            delete this.subscriptions[sub];
          }
        }
      );
    }
  }

  render() {
    const { children, session } = this.props;
    const newProps = {
      data: this.state.data,
      session,
      subscribe: this.subscribe,
      unsubscribe: this.unsubscribe
    };

    const childrenWithProps = React.Children.map(
      children,
      (child) => React.cloneElement(child, newProps)
    );

    return (
      <Fragment>
        { childrenWithProps }
      </Fragment>
    );

  }

}

SubscriptionManager.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  session: PropTypes.object
};

export default withGracefulUnmount(SubscriptionManager);
