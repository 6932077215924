import React from 'react';
import PropTypes from 'prop-types';

const WindowsManagerContext = React.createContext();

export const withWindowsManager = (Component) => {
  return function WindowManagedComponent(props) {
    return (
      <WindowsManagerContext.Consumer>
        {
          (windowsManager) => (
            <Component
              {...props}
              windowsManager={windowsManager}
            />
          )
        }
      </WindowsManagerContext.Consumer>
    );
  };
};

export const WindowsManagerProvider = ({ children, windowsManager }) => (
  <WindowsManagerContext.Provider value={windowsManager}>
    { children }
  </WindowsManagerContext.Provider>
);

WindowsManagerProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  windowsManager: PropTypes.object.isRequired
};


export { default as webWindowsManager } from './webWindowsManager';
