import React from 'react';
import PropTypes from 'prop-types';

import Fullscreen from 'components/Fullscreen';
import TimingScreen from 'screens/TimingScreen';
import TimingMenu from 'components/TimingMenu';

const InteractiveTimingScreen = ({
  analysisRootPath,
  data: {
    session,
    cars,
    messages,
    highlight
  },
  delay,
  service,
  setDelay
}) => {
  const [ isFullscreen, setFullscreen ] = React.useState(false);
  const [ showAnimations, setShowAnimations ] = React.useState(true);
  const [ showBackgroundColours, setShowBackgroundColours ] = React.useState(true);
  const [ showColumnsDialog, setShowColumnsDialog ] = React.useState(false);
  const [ isCompactMode, setCompactMode ] = React.useState(false);
  const [ fontScale, setFontScale ] = React.useState(1);

  const toggleColumnsDialog = () => setShowColumnsDialog(!showColumnsDialog);
  const toggleCompactMode = () => setCompactMode(!isCompactMode);

  const timingScreenRef = React.createRef();

  React.useEffect(
    () => {
      if (timingScreenRef.current) {
        if (timingScreenRef.current.clientWidth < 750) {
          setCompactMode(true);
        }
      }
    },
    []
  );

  const menu = (
    <TimingMenu
      analysisRootPath={analysisRootPath}
      delay={delay}
      fontScale={fontScale}
      isCompactMode={isCompactMode}
      isFullscreen={isFullscreen}
      service={service}
      setDelay={setDelay}
      setFontScale={setFontScale}
      setFullscreen={setFullscreen}
      showAnimations={showAnimations}
      showBackgroundColours={showBackgroundColours}
      toggleColumnsDialog={toggleColumnsDialog}
      toggleCompactMode={toggleCompactMode}
      toggleShowAnimations={() => setShowAnimations(!showAnimations)}
      toggleShowBackgroundColours={() => setShowBackgroundColours(!showBackgroundColours)}
    />
  );

  return (
    <Fullscreen
      enabled={isFullscreen}
      onChange={setFullscreen}
    >
      {
        !!service ?
          <TimingScreen
            cars={cars}
            fontScale={fontScale}
            highlight={highlight}
            innerRef={timingScreenRef}
            isCompactMode={isCompactMode}
            menu={menu}
            messages={messages}
            service={service}
            session={session}
            showAnimations={showAnimations}
            showBackgroundColours={showBackgroundColours}
            showColumnsDialog={showColumnsDialog}
            toggleColumnsDialog={toggleColumnsDialog}
          />
          : null
      }

    </Fullscreen>
  );
};

InteractiveTimingScreen.propTypes = {
  analysisRootPath: PropTypes.string.isRequired,
  data: PropTypes.shape({
    cars: PropTypes.array.isRequired,
    highlight: PropTypes.array,
    messages: PropTypes.array.isRequired,
    session: PropTypes.object.isRequired
  }),
  delay: PropTypes.number,
  service: PropTypes.object.isRequired,
  setDelay: PropTypes.func.isRequired
};

InteractiveTimingScreen.defaultProps = {
  analysisRootPath: '/analysis'
};

export default InteractiveTimingScreen;
