import ga from 'react-ga';

import * as Sentry from '@sentry/browser';

import ConnectionStates from './connectionStates';
import ProxySession from './proxySession';
import { MPI } from './constants';

export default class SharedConnectionManager {
  constructor() {
    this.state = ConnectionStates.UNLOADED;
    this.connection = null;
    this.session = null;
    this.onconnectionstatechange = () => {};
    this.onconnectionerror = () => {};

    this._receive = this._receive.bind(this);
    this._send = this._send.bind(this);

    this._worker = new SharedWorker('./sharedWorker.js', { type: 'module' });
    this._worker.port.addEventListener("message", this._receive, false);

    this._worker.port.start();
  }

  connect(relay=null) {
    this._send(MPI.CONNECT, relay);
    ga.event({
      category: 'Networking',
      action: 'MPI connection',
      nonInteraction: true
    });
  }

  close() {
    if (this.session) {
      this.session.unsubscribeAll();
      this.session = null;
    }
    this._send(MPI.CLOSE);
  }

  _send(type, ...args) {
    this._worker.port.postMessage([type, args]);
  }

  _receive(msg) {
    if (Array.isArray(msg.data)) {
      const [method, ...args] = msg.data;

      if (this[method]) {
        this[method](...args);
      }
      else {
        console.warn(`I don't know how to handle ${method}`);
      }

    }
  }

  connectionStateChange(newState, info) {
    this.state = newState;

    if (this.state !== ConnectionStates.CONNECTED) {
      this.session = null;
    }
    else {
      console.info(`Established connection to ${info ? info.url : 'unknown server'}`);

      if (info && info.url) {
        Sentry.configureScope(
          (scope) => scope.setExtra('relay', info.url)
        );
      }
    }
    this.onconnectionstatechange(newState);
  }

  setSession(sessionId) {
    console.debug("Setting session id", sessionId);
    this.session = new ProxySession(sessionId, this._send);
    this.onconnectionstatechange(this.state);
  }

  subscriptionMessage(correlationID, data, kwargs, details) {
    if (this.session) {
      this.session.subscriptionMessage(correlationID, data, kwargs, details);
    }
  }

  callResult(correlationID, result) {
    if (this.session) {
      this.session.callResult(correlationID, result);
    }
  }

  callResultRejected(correlationID, error) {
    if (this.session) {
      this.session.callResultRejected(correlationID, error);
    }
  }

  error(reason, details) {
    this.onconnectionerror(reason, details);
  }
}
