import React from 'react';

const TrackData = ({ spec, dataset }) => {
  if (spec && dataset) {
    const data = [];

    for (let i = 0; i < spec.length; i++) {
      data.push(
        <div
          className="trackdata-key"
          key={2 * i}
        >
          {spec[i]}
        </div>
      );
      data.push(
        <div
          className="trackdata-value"
          key={(2 * i) + 1}
        >
          {dataset[i]}
        </div>
      );
    }

    return (
      <div className="trackdata-container h-100">
        {data}
      </div>
    );
  }
  else {
    return (
      <div className="trackdata-container h-100">
        <p className="trackdata-message">
          Track data not available
        </p>
      </div>
    );
  }
};

export default TrackData;
