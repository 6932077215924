import _ from 'lodash';
import autobahn from 'autobahn';
import * as Sentry from '@sentry/browser';

import { RELAY_LIST_URL } from 'config/settings';
import ConnectionStates from './connectionStates';

const ConnectionError = (msg) => ({
  name: 'ConnectionError',
  message: msg
});

const sortRelaysByUser = (relays) => _.sortBy(_.keys(relays), (r) => relays[r][0]);

export default class LocalConnectionManager {

  constructor() {
    this.state = ConnectionStates.UNLOADED;
    this.connection = null;
    this.session = null;
    this.onconnectionstatechange = () => {};
    this.onconnectionerror = () => {};
    this.relays = [];

    this._handleError = this._handleError.bind(this);

    console.info(`Using Autobahn.js version ${autobahn.version}`);
  }

  connect(relay=null) {
    if (
      this.state !== ConnectionStates.UNLOADED &&
      this.state !== ConnectionStates.ERRORED
    ) {
      throw ConnectionError('Connection already established');
    }

    if (relay !== null) {
      console.log("Explicit relay:", relay);
      this.relays.push(relay);
      this._connectToRelay();
    }
    else {
      this._fetchRelayList();
    }
  }

  close() {
    if (this.connection && this.connection.isOpen) {
      this.connection.close();
    }
    this.session = null;
    this.relays = [];
    this._setState(ConnectionStates.UNLOADED);
  }

  _setState(newState) {
    this.state = newState;
    this.onconnectionstatechange(newState);
  }

  _fetchRelayList() {
    this._setState(ConnectionStates.LOADING_RELAYS);
    fetch(RELAY_LIST_URL).then(
      (relays) => relays.json().then(
        (relayJson) => {
          const sortedRelays = sortRelaysByUser(
            relayJson.args[0]
          );

          this.relays = sortedRelays;
          this._setState(ConnectionStates.RELAYS_LOADED);

          this._connectToRelay();
        }
      )
    );
  }

  _connectToRelay() {
    if (this.relays.length === 0) {
      this._setState(ConnectionStates.ERRORED);
      this._handleError('unreachable', { will_retry: false }); //eslint-disable-line camelcase
    }
    else {
      const relay = this.relays[0];

      this.connection = new autobahn.Connection({
        transports: [{
          type: 'websocket',
          url: relay
        }],
        realm: "timing",
        max_retries: 3, // eslint-disable-line camelcase
        on_internal_error: this.handleError // eslint-disable-line camelcase
      });

      this._setState(ConnectionStates.CONNECTING);

      this.connection.onopen = (session) => {
        session.log(`Established connection to ${session._socket.info.url}`);
        Sentry.configureScope(
          (scope) => scope.setExtra('relay', session._socket.info.url)
        );
        this.session = session;
        this._setState(ConnectionStates.CONNECTED);
      };

      this.connection.onclose = this._handleError;

      this.connection.open();
    }

  }

  _handleError(reason, details) {
    console.warn("Handling connection error: ", reason, details);

    if (this.state === ConnectionStates.CONNECTING) {
      if (details && !details.will_retry) {
        this.relays = this.relays.slice(1);
        this._connectToRelay();
      }
    }
    else if (details.reason === 'wamp.close.normal') {
      this._setState(ConnectionStates.UNLOADED);
    }
    else {
      this._setState(ConnectionStates.ERRORED);
      this.onconnectionerror(reason, details);
    }
  }
}
