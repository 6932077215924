import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import DocumentTitle from 'react-document-title';

import {
  Container, Row, Col,
  Form, FormGroup, Input, Label
} from 'reactstrap';

import SVG from 'react-inlinesvg';

import config, { APP_NAME } from 'config/settings';
import Header from '../components/Header';
import Paginator from '../components/Paginator';
import RecordingCard from '../components/RecordingCard';

import svgLogo from 'images/logo_no_text.svg';
import RecordingEditModal from '../components/RecordingEditModal';
import { withPreferences } from 'components/Preferences';

class RecordingListScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editingRecording: null,
      filter: '',
      loading: true,
      names: [],
      page: 1,
      pages: null,
      recordings: []
    };

    this.editRecording = this.editRecording.bind(this);
  }

  componentDidMount() {
    this.updateNames();
    this.updateRecordings();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.session !== this.props.session) {
      this.updateNames();
      this.updateRecordings();
    }
    else if (!this.state.loading && (prevState.page !== this.state.page || prevState.filter !== this.state.filter)) {
      this.updateRecordings();
    }
  }

  updateNames() {
    const { preferences, session } = this.props;
    if (session) {
      session.call(
        config.RPC.GET_RECORDINGS_NAMES,
        [ preferences && preferences.get('behaviour.show_hidden', false) ]
      ).then(
        (result) => this.setState({ names: result })
      );
    }
  }

  updateRecordings() {
    const { preferences, session } = this.props;
    const { filter, page } = this.state;
    if (session) {
      this.setState({ loading: true });
      session.call(
        config.RPC.GET_RECORDINGS_PAGE,
        [
          page,
          filter === '' ? null : filter,
          preferences && preferences.get('behaviour.show_hidden', false)
        ]
      ).then(
        (result) => {
          this.setState({
            loading: false,
            ...result,
            page: Math.min(this.state.page, result.pages)
          });
        },
        (err) => {
          console.error(err);
          this.setState({ loading: false });
        }
      ).catch(
        (err) => {
          console.error("Error caught:", err);
          this.setState({ loading: false });
        }
      );
    }
  }

  editRecording(recording) {
    this.setState({
      editingRecording: recording
    },
    () => {
      if (!recording) {
        this.updateRecordings();
      }
    });
  }


  render() {
    const { names, recordings, page, total, loading } = this.state;
    return (
      <DocumentTitle title={`Previous events | ${APP_NAME}`}>
        <Container
          className="h-100 d-flex flex-column p-0"
          fluid
        >
          <Header currentPage='replays' />
          <Container
            className="content"
            fluid
          >
            <Row>
              <Col
                lg={6}
                xs={12}
              >
                <Form
                  className='justify-content-center'
                  inline
                >
                  <FormGroup>
                    <Label
                      className='mr-sm-2'
                      for='serviceFilter'
                    >Series</Label>
                    <Input
                      id='serviceFilter'
                      name='serviceFilter'
                      onChange={(evt) => this.setState({ filter: evt.target.value })}
                      type='select'
                    >
                      <option value=''>Show all</option>
                      {
                        _(names)
                          .sortBy((r) => r.toLowerCase())
                          .map((r, idx) => (
                            <option
                              key={idx}
                              value={r}
                            >
                              {r}
                            </option>
                          ))
                          .value()
                      }
                    </Input>
                  </FormGroup>
                </Form>
              </Col>
              <Col
                lg={6}
                xs={12}
              >
                <Paginator
                  handleChangePage={(p) => this.setState({ page: p })}
                  itemCount={total}
                  page={page}
                  pageSize={50}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Row>
                  {
                    loading ?
                      <div className='screen-loading'>
                        <SVG
                          className='loading-spinner'
                          src={svgLogo}
                          uniquifyIDs={false}
                        />
                      </div>
                      :
                      recordings.map((r) => (
                        <RecordingCard
                          editRecording={this.editRecording}
                          key={r.uuid}
                          lg={3}
                          md={6}
                          recording={r}
                          showDetails
                          xs={12}
                        />
                      ))
                  }
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                lg={{
                  offset: 6,
                  size: 6
                }}
                xs={12}
              >
                <Paginator
                  handleChangePage={(p) => this.setState({ page: p })}
                  itemCount={total}
                  page={page}
                  pageSize={50}
                />
              </Col>
            </Row>
          </Container>
          <RecordingEditModal
            onClose={() => this.editRecording(null)}
            recording={this.state.editingRecording}
            session={this.props.session}
          />
        </Container>
      </DocumentTitle>
    );
  }
}

RecordingListScreen.propTypes = {
  preferences: PropTypes.shape({
    get: PropTypes.func.isRequired
  }),
  session: PropTypes.object
};

export default withPreferences(RecordingListScreen);
