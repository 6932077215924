import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';

import { Table, UncontrolledTooltip } from 'reactstrap';

import { format, classNameFromCategory } from 'utils/formats';

function findStateIndex(columnSpec) {
  for (let i=0; i < columnSpec.length; i++) {
    if (columnSpec[i][0] === "State") {
      return i;
    }
  }
  return -1;
}

function findClassIndex(columnSpec) {
  for (let i=0; i < columnSpec.length; i++) {
    if (columnSpec[i][0] === "Class") {
      return i;
    }
  }
  return -1;
}

function findRaceNumberIndex(columnSpec) {
  for (let i=0; i < columnSpec.length; i++) {
    if (columnSpec[i][0] === "Num") {
      return i;
    }
  }
  return -1;
}

const TimingRow = ({ position, car, columnSpec, highlight, hideColumns }) => {
  const cols = [
    <td
      className="timing-row-position column_Pos"
      key={0}
    >
      {position}
    </td>
  ];
  _(columnSpec).forEach((col, index) => {
    if (!hideColumns.includes(col[0])) {
      const valTuple = car[index];
      let value, flags;
      if (typeof(valTuple) === "object" && valTuple !== null) {
        value = valTuple[0];
        flags = valTuple[1];
      }
      else {
        value = valTuple;
        flags = "";
      }
      cols.push(
        <td
          className={`column_${col[0]} ${flags}`}
          key={index + 1}
        >
          {format(value, col[1])}
        </td>
      );
    }
  });

  const hasSetSB = _(car).find((c) => (typeof(c) === "object" && c !== null && c[1] === "sb-new"));
  const stateCol = findStateIndex(columnSpec);
  const classCol = findClassIndex(columnSpec);
  const carClass = classNameFromCategory(car[classCol]);
  const carNumIdx = findRaceNumberIndex(columnSpec);
  const carNum = carNumIdx > -1 ? car[carNumIdx] : null;
  const doHighlight = carNum && _.includes(highlight, carNum);
  const carState = (car[stateCol] || '?').replace(/[/ ]/g, "");
  return (
    <tr
      className={
        classnames(
          `car_state_${carState}`,
          `car_class_${carClass}`,
          {
            'sb-new': hasSetSB,
            'highlight-line': doHighlight
          }
        )
      }
      id={`car_${carNum}`}
    >
      {cols}
    </tr>
  );
};

TimingRow.propTypes = {
  car: PropTypes.array.isRequired,
  columnSpec: PropTypes.arrayOf(PropTypes.array).isRequired,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  highlight: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  position: PropTypes.number.isRequired
};

const TimingTableHeader = ({ spec }) => {
  const specId = spec[0].replace(/[. ]/g, '_');
  if (spec.length === 3) {
    return (
      <td className={`column_${specId}`}>
        <span
          className="with-tooltip"
          id={`timing-table-header-tooltip-${specId}`}
        >{spec[0]}</span>
        <UncontrolledTooltip
          delay={0}
          placement='bottom'
          target={`timing-table-header-tooltip-${specId}`}
        >
          {spec[2]}
        </UncontrolledTooltip>
      </td>);
  }
  else {
    return <td className={`column_${specId}`}>{spec[0]}</td>;
  }
};

const TimingTable = ({ cars, columnSpec, highlight, showAnimations, showBackgroundColours, hideColumns }) => {
  return (
    <Table
      className={
        classnames(
          'timing-table',
          'table-condensed',
          {
            'use-backgrounds': showBackgroundColours,
            'use-animations': showAnimations
          }
        )
      }
      striped
    >
      <thead>
        <tr className="timing-table-header">
          <td className='column_Pos'>Pos</td>
          {
            columnSpec.map(
              (spec, idx) => hideColumns.includes(spec[0]) ? null : (
                <TimingTableHeader
                  key={idx}
                  spec={spec}
                />
              )
            )
          }
        </tr>
      </thead>
      <tbody>
        {
          cars.filter(
            (c) => !!c
          ).map(
            (car, position) => (
              <TimingRow
                car={car}
                columnSpec={columnSpec}
                hideColumns={hideColumns}
                highlight={highlight}
                key={car[0]}
                position={position + 1}
              />
            )
          )
        }
      </tbody>
    </Table>
  );
};

TimingTable.propTypes = {
  cars: PropTypes.arrayOf(PropTypes.array),
  columnSpec: PropTypes.arrayOf(PropTypes.array),
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  highlight: PropTypes.arrayOf(PropTypes.string),
  showAnimations: PropTypes.bool,
  showBackgroundColours: PropTypes.bool
};

TimingTable.defaultProps = {
  hideColumns: []
};

export default TimingTable;
