import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col, Table } from 'reactstrap';
import { Link } from 'components/LinkContainer';

import Header from '../components/Header';
import { subscriptions, withSubscription } from '../modules/network';

const serviceManifestType = PropTypes.shape({
  description: PropTypes.string.isRequired,
  hasAnalysis: PropTypes.bool,
  hidden: PropTypes.bool,
  name: PropTypes.string.isRequired,
  pollInterval: PropTypes.number,
  serviceClass: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired
});

const ServiceEntry = ({ service }) => (
  <tr>
    <td>
      <Link
        newWindow='electron'
        to={`/timing/${service.uuid}`}
      >
        {service.uuid}
      </Link>
    </td>
    <td>
      <Link
        newWindow='electron'
        to={`/s/${service.serviceClass}`}
      >
        {service.serviceClass}
      </Link>
    </td>
    <td>{service.name}</td>
    <td>{service.description}</td>
    <td>{service.pollInterval}</td>
    <td>{service.hasAnalysis ? 'Yes' : 'No'}</td>
    <td>{service.hidden ? 'Yes' : 'No'}</td>
  </tr>
);

ServiceEntry.propTypes = {
  service: serviceManifestType
};

const AllServicesScreen = ({ services }) => (
  <Container
    className="h-100 d-flex flex-column"
    fluid
  >
    <Header />
    <Container
      className="content"
      fluid
    >
      <Row>
        <Col sm={12}>
          <h2>Live services</h2>
          <Table
            className='timing-table'
            responsive
            striped
          >
            <thead>
              <tr className='timing-table-header'>
                <td>UUID</td>
                <td>Service class</td>
                <td>Name</td>
                <td>Description</td>
                <td>Poll Int</td>
                <td>Analysis</td>
                <td>Hidden</td>
              </tr>
            </thead>
            <tbody>
              {
                services.map(
                  (s, key) => (
                    <ServiceEntry
                      key={key}
                      service={s}
                    />
                  )
                )
              }
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  </Container>
);

AllServicesScreen.propTypes = {
  services: PropTypes.arrayOf(serviceManifestType)
};

AllServicesScreen.defaultProps = {
  services: []
};

export default withSubscription(
  AllServicesScreen,
  [
    subscriptions.SERVICES
  ]
);
