import React from 'react';

import PropTypes from 'prop-types';

import { Tooltip } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

const CompactModeHint = ({ isCompactMode, target }) => {

  const [ isShowing, setShowing ] = React.useState(false);

  React.useEffect(
    () => {
      if (isCompactMode) {
        setShowing(true);
        const timeout = setTimeout(
          () => setShowing(false),
          5000
        );

        return () => clearTimeout(timeout);
      }
      else {
        setShowing(false);
      }
    },
    [isCompactMode]
  );

  return (
    <Tooltip
      isOpen={isShowing}
      placement='bottom-end'
      target={target}
      toggle={() => setShowing(!isShowing)}
      trigger='none'
    >
      <FontAwesome
        className='mr-2'
        name='info-circle'
      />
      Displaying in compact mode
    </Tooltip>
  );
};

CompactModeHint.propTypes = {
  isCompactMode: PropTypes.bool,
  target: PropTypes.string.isRequired
};

export default CompactModeHint;
