import React from 'react';
import PropTypes from 'prop-types';

import { withWindowsManager } from 'windowsManager';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(
    event.metaKey ||
    event.altKey ||
    event.ctrlKey ||
    event.shiftKey
  );
}

const LinkContainer = ({
  children,
  to,
  onClick,
  newWindow,
  windowsManager,
  ...others
}) => React.cloneElement(
  React.Children.only(children),
  {
    ...others,
    onClick: (evt) => {

      if (children.props.onClick) {
        children.props.onClick(evt);
      }

      if (onClick) {
        onClick(event);
      }

      if (
        !evt.defaultPrevented &&
        isLeftClickEvent(evt) &&
        !isModifiedEvent(evt)
      ) {
        evt.preventDefault();
        windowsManager.navigate(to, newWindow);
      }
    }
  }
);

LinkContainer.propTypes = {
  children: PropTypes.node.isRequired,
  newWindow: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  to: PropTypes.string.isRequired,
  windowsManager: PropTypes.object.isRequired
};

LinkContainer.defaultProps = {
  newWindow: false
};

const ManagedLinkContainer = withWindowsManager(LinkContainer);
export default ManagedLinkContainer;

export const Link = ({ children, onClick, newWindow, to, ...others }) => (
  <ManagedLinkContainer
    newWindow={newWindow}
    to={to}
  >
    <a
      href=""
      onClick={onClick}
      {...others}
    >
      {children}
    </a>

  </ManagedLinkContainer>
);
