import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import queryString from 'query-string';

import SubscriptionManager from './SubscriptionManager';

import { ErrorModal, Loading } from 'components/Modals';
import withGracefulUnmount from 'components/withGracefulUnmount';
import { ConnectionStates } from '../connectionManager';
import { withConnectionManager } from './ConnectionProvider';

const relayFromURL = () => queryString.parse(window.location.search).relay;

const errorMap = {
  "closed": "Disconnected from the timing server.",
  "unreachable": "Could not connect to the timing server.",
  "lost": "Lost connection to the timing server."
};

const mapError = (error) => {
  const { details, reason } = error;
  console.error('Connection error:', error); // eslint-disable-line

  return (
    <ErrorModal>
      Error: {errorMap[reason] || reason || "An unspecified error occurred."}
      {details.will_retry ? ' Trying to reconnect...' : ' Please refresh the page to try again.'}
    </ErrorModal>
  );
};

class AutobahnConnection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectionState: null,
      error: null
    };

    this.loadingCaption = this.loadingCaption.bind(this);
  }

  componentDidMount() {
    const { connectionManager } = this.props;

    connectionManager.onconnectionstatechange = (s) => {
      this.setState({ connectionState: s });
      if (s === ConnectionStates.CONNECTED) {
        this.setState({ error: undefined });
      }
    };
    connectionManager.onconnectionerror = (reason, details) => this.setState({ error: { reason, details } });

    connectionManager.connect(relayFromURL());
  }

  componentWillUnmount() {
    this.props.connectionManager.close();
  }

  loadingCaption() {
    switch (this.state.connectionState) {
      case ConnectionStates.LOADING_RELAYS:
        return 'Loading server list';
      case ConnectionStates.RELAYS_LOADED:
        return 'Loaded server list';
      case ConnectionStates.CONNECTING:
        return 'Connecting to server...';
      default:
        return 'Connecting...';
    }
  }

  render() {
    const { children, connectionManager, suppressLoadingModal } = this.props;
    const { connectionState } = this.state;

    const childrenWithProps = React.Children.map(
      children,
      (child) => React.cloneElement(
        child,
        {
          connection: {
            error: this.state.error,
            state: connectionState
          },
          session: connectionManager.session
        }
      )
    );
    return (
      <Fragment>
        <Loading
          caption={this.loadingCaption()}
          isOpen={
            !suppressLoadingModal && (
              connectionState < ConnectionStates.CONNECTED ||
              !connectionManager.session
            )
          }
        />
        {
          this.state.error && !suppressLoadingModal ?
            mapError(this.state.error)
            : null
        }
        <SubscriptionManager session={connectionManager.session}>
          { childrenWithProps }
        </SubscriptionManager>
      </Fragment>
    );
  }
}

AutobahnConnection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  connectionManager: PropTypes.object.isRequired,
  suppressLoadingModal: PropTypes.bool
};

const ManagedAutobahnConnection = withConnectionManager(
  withGracefulUnmount(
    AutobahnConnection
  )
);

export default ManagedAutobahnConnection;

export const UnmodalAutobahnConnection = (props) => (
  <ManagedAutobahnConnection
    suppressLoadingModal
    {...props}
  />
);
