import React, { useState } from 'react';

import { Container, Row, Col } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router';
import classnames from 'classnames';

import { SHOW_HIDDEN } from 'config/settings';

import Copyright from '../components/Copyright';
import Version from '../components/Version';
import Header from '../components/Header';
import Panel from '../components/Panel';

import menuImage from 'images/menu.png';
import columnsImage from 'images/columns.png';

const FlagsHelp = () => {

  const [ showAnimation, setShowAnimation ] = useState(false);

  const flagClasses = {
    'flag-status': showAnimation,
    'p-2': true,
    'text-center': true
  };

  return (
    <Col
      className={classnames({ 'help-screen-flags': !showAnimation })}
      md={4}
    >
      <p>
        For flags:{ ' ' }
        {
          showAnimation ?
            null :
            <a
              onClick={
                () => {
                  setShowAnimation(true);
                  window.setTimeout(
                    () => {
                      setShowAnimation(false);
                    },
                    8000
                  );
                }
              }
              tabIndex={10}
            >
              (Show animation)
            </a>
        }
      </p>
      <div className={classnames(flagClasses, 'flag-status-green')}>Green flag - track clear</div>
      <div className={classnames(flagClasses, 'flag-status-yellow')}>Yellow flag - warning of danger in one part of track</div>
      <div className={classnames(flagClasses, 'flag-status-sc')}>Safety car / Caution - all cars queue up behind SC</div>
      <div className={classnames(flagClasses, 'flag-status-code_60')}>Code 60 - all cars slow to 60kph</div>
      <div className={classnames(flagClasses, 'flag-status-fcy')}>Full course yellow / Virtual SC - all cars slow down to speed limit</div>
      <div className={classnames(flagClasses, 'flag-status-red')}>Red flag - session suspended</div>
      <div className={classnames(flagClasses, 'flag-status-code_60_zone')}>Code 60 zone(s) in use</div>
      <div className={classnames(flagClasses, 'flag-status-slow_zone')}>Slow zone(s) in use</div>
      <div
        className='flag-status flag-status-chequered p-2'
        style={{ color: 'black', textShadow: 'white 1px 1px 0px' }}
        title='End of session / race'
      >
        End of session / race
      </div>
    </Col>
  );

};


const HelpScreen = () => (
  <Container
    className="h-100 d-flex flex-column p-0"
    fluid
  >
    <Header currentPage='help' />
    <Container
      className="content"
      fluid
    >
      <Row>
        <Col>
          <Panel header='What is Timing71?'>
            <p>
              Timing71 (formerly the Live Timing Aggregator) is a live timing aggregation service: it collects live
              timing data from a variety of different motorsports, and makes them all available via a unified interface,
              complete with additional analysis both during and after the event.
            </p>
            <p>
              Full timing replays of all events are also available, if you're watching on catch-up!
            </p>
          </Panel>
          <Panel header='How do I use the key features?'>
            <p>
              For a given event, most features can be accessed through the dropdown menu at the top-right of the timing screen:
            </p>
            <div className='d-flex align-items-start'>
              <img
                className='px-2'
                src={menuImage}
              />
              <div className='p-2 pt-4'>
                <p>
                  Often, when viewing a live stream of an event, the live timing feed will be ahead of the video, sometimes by
                  as much as a minute or more. You can delay the live timing by increasing the value in this menu, until your
                  timing is in sync with the video feed.
                </p>
                <p>
                  Launching analysis will open a new window with a variety of visualisations and data displays relating to the
                  current session. Analysis is best viewed in a desktop browser.
                </p>
                <p>
                  You can toggle full-screen on and off, or use the F11 key on your keyboard.
                </p>
                <p>
                  When a car is mentioned by a message at the bottom of the timing screen, its timing row will
                  flash. You can turn off this animation in this menu.
                </p>
                <p>
                  You can also turn off the background colours that indicate whether a car is in the pits.
                </p>
                <p>
                  You can configure which columns are shown and which are hidden (right). For some series, a lot of information
                  is available, and you may choose to hide any columns which you're not interested in. The columns available will
                  depend on the series and session you're viewing. The changes you make are not (currently) saved when you stop
                  viewing the timing.
                </p>
              </div>
              <img
                className='px-2'
                src={columnsImage}
              />
            </div>
          </Panel>
          <Panel header='What do the different colours mean?'>
            <Container
              className='use-backgrounds use-animation'
              fluid
            >
              <Row>
                <Col md={4}>
                  <p>For time data:</p>
                  <ul>
                    <li className='pb'>Personal best</li>
                    <li className='sb'>Overall best</li>
                    <li className='old'>Old(er) data (e.g. previous lap)</li>
                    <li>Current data</li>
                    <li className='joker'>Joker lap (applicable in some series rules)</li>
                  </ul>

                  <p>Tyres are also colour-coded where data is available:</p>
                  <ul>
                    <li className='tyre-hard'>Hard / primary</li>
                    <li className='tyre-med'>Medium</li>
                    <li className='tyre-soft'>Soft / alternate</li>
                    <li className='tyre-inter'>Intermediate</li>
                    <li className='tyre-wet'>Wet</li>
                  </ul>
                </Col>
                <Col md={4}>
                  <p>For car state:</p>
                  <table className='table timing-table'>
                    <tbody>
                      <tr className='car_state_RUN'>
                        <td className='column_State'>
                          RUN
                        </td>
                        <td>
                          Car is running on track
                        </td>
                      </tr>
                      <tr className='car_state_PIT'>
                        <td className='column_State'>
                          PIT
                        </td>
                        <td>
                          Car is in the pits
                        </td>
                      </tr>
                      <tr className='car_state_OUT'>
                        <td className='column_State'>
                          OUT
                        </td>
                        <td>
                          Car has just exited the pits
                        </td>
                      </tr>
                      <tr className='car_state_FIN'>
                        <td className='column_State'>
                          FIN
                        </td>
                        <td>
                          Car has finished
                        </td>
                      </tr>
                      <tr className='car_state_STOP'>
                        <td className='column_State'>
                          STOP
                        </td>
                        <td>
                          Car has stopped or is running slowly on circuit
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <FlagsHelp />
              </Row>
            </Container>
            <p>
              In addition, different <i>classes</i> of entrant will have their own assigned colours - such
              as <span className='category-lmp1'>LMP1</span> vs <span className='category-lmp2'>LMP2</span>.
              Class colours are consistent across all events (which means they might not match up with TV
              coverage of a particular event!)
            </p>
          </Panel>
        </Col>
      </Row>
    </Container>
    <Row className="footer">
      <Col sm={12}>
        {
          SHOW_HIDDEN ?
            <Link to='/services'>
              <FontAwesome
                className='pull-right'
                name='key'
              />
            </Link>
            : null
        }
        <Version />
        <Copyright />
      </Col>
    </Row>
  </Container>
);

export default HelpScreen;
