import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

export default (WrappedComponent, subscriptionSpec) => {
  class WithSubscription extends React.Component {

    componentDidMount() {
      const { subscribe } = this.props;
      _.forEach(
        subscriptionSpec,
        (sub) => subscribe(sub)
      );
    }

    componentWillUnmount() {
      const { unsubscribe } = this.props;
      _.forEach(
        subscriptionSpec,
        (sub) => unsubscribe(sub)
      );
    }

    render() {
      const { data, ...others } = this.props;
      const filteredData = _.pick(
        data,
        _.map(
          subscriptionSpec,
          (spec) => spec[2]
        )
      );

      return (
        <WrappedComponent
          {...filteredData}
          {...others}
        />
      );
    }
  }

  WithSubscription.propTypes = {
    data: PropTypes.object,
    session: PropTypes.object,
    subscribe: PropTypes.func,
    unsubscribe: PropTypes.func
  };

  return WithSubscription;
};
