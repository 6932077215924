import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import LZString from 'lz-string';
import ga from 'react-ga';

import InteractiveTimingScreen from './InteractiveTimingScreen';
import config from 'config/settings';
import { subscriptions, withSubscription } from '../modules/network';
import TimingDelay from '../modules/timingDelay';
import { Loading, ServiceNotAvailable } from 'components/Modals';


const findServiceFromContext = (context, uuid) => {
  return _(context.services).find((svc) => svc.uuid === uuid);
};

class TimingServiceProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        cars: [],
        messages: [],
        session: {
          flagState: 'none',
          timeElapsed: 0,
          timeRemain: 0
        }
      },
      delay: 0
    };

    this.handleData = this.handleData.bind(this);
    this.setDelay = this.setDelay.bind(this);
    this._delayer = new TimingDelay(this.applyMessage.bind(this));
  }

  static getDerivedStateFromProps(props, state) {
    const service = findServiceFromContext(props, props.params.serviceUUID);

    if (service && service !== state.service) {
      return { service };
    }

    return null;
  }

  componentDidMount() {
    const pingGA = () => {
      ga.event({
        category: 'heartbeat',
        action: 'ping',
        nonInteraction: true
      });
    };

    const interval = window.setInterval(pingGA, 5 * 60 * 1000);  // Five minutes
    this.gaPingInterval = interval;

    this.subscribeToService(this.state.service);
  }

  componentDidUpdate(_prevProps, prevState) {

    const { service } = this.state;
    const prevService = prevState.service;

    const prevServiceUUID = prevService ? prevService.uuid : null;

    if (service && service !== prevService) {
      if (prevServiceUUID) {
        this.props.unsubscribe([ config.RPC.STATE_PUBLISH.replace("{}", prevServiceUUID), this.handleData ]);
      }
      this.subscribeToService(service);
    }
  }

  componentWillUnmount() {
    const { params: { serviceUUID }, unsubscribe } = this.props;

    unsubscribe([ config.RPC.STATE_PUBLISH.replace("{}", serviceUUID), this.handleData ]);

    this._delayer.cancel();

    if (this.gaPingInterval) {
      window.clearInterval(this.gaPingInterval);
    }
  }

  subscribeToService(service) {
    const { subscribe } = this.props;
    if (service) {
      subscribe([config.RPC.STATE_PUBLISH.replace("{}", service.uuid), this.handleData]);
    }
  }

  handleData(data) {
    _(data).forEach((message) => {
      if (message.msgClass === config.MessageClass.SERVICE_DATA) {
        this._delayer.delayMessage(message.payload);
      }
      else if (message.msgClass === config.MessageClass.SERVICE_DATA_COMPRESSED) {
        const msgContent = JSON.parse(LZString.decompressFromUTF16(message.payload));
        this._delayer.delayMessage(msgContent);
      }
    });
  }

  applyMessage(payload) {
    this.setState({
      data: {
        cars: payload.cars,
        session: payload.session,
        messages: payload.messages,
        highlight: payload.highlight
      }
    });
  }

  setDelay(delay) {
    this.setState({ delay: delay });
    this._delayer.setDelay(delay);
  }

  render() {
    const { delay, service, data } = this.state;
    const { services, showMenuButton } = this.props;

    if (services === undefined) {
      return <Loading />;
    }
    else if (!service) {
      return (
        <ServiceNotAvailable
          isOpen
          showMenuButton={showMenuButton}
        />
      );
    }

    return (
      <InteractiveTimingScreen
        data={data}
        delay={delay}
        service={service}
        setDelay={this.setDelay}
      />
    );

  }

}

TimingServiceProvider.propTypes = {
  params: PropTypes.object,
  services: PropTypes.arrayOf(PropTypes.object),
  showMenuButton: PropTypes.bool,
  subscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired
};

TimingServiceProvider.defaultProps = {
  showMenuButton: false
};

export default withSubscription(
  TimingServiceProvider,
  [ subscriptions.SERVICES ]
);
