import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Form, FormGroup, Input, Label,
  Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';
import SVG from 'react-inlinesvg';

import svgLogo from 'images/logo_no_text.svg';

import { isEqual } from 'lodash';

import config from 'config/settings';

const ManifestInput = ({ fieldName, manifest, original, setField }) => (
  <Input
    onChange={(e) => setField(fieldName, e.target.value)}
    type='text'
    valid={manifest[fieldName] !== original[fieldName]}
    value={manifest[fieldName]}
  />
);

ManifestInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  manifest: PropTypes.object.isRequired,
  original: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired
};

const EDITABLE_FIELDS = {
  name: 'Name',
  description: 'Description'
};

const RecordingEditForm = ({ manifest, original, setField }) => (
  <Form>
    {
      Object.keys(EDITABLE_FIELDS).map(
        (fieldName, idx) => (
          <FormGroup key={idx}>
            <Label>
              { EDITABLE_FIELDS[fieldName] }
            </Label>
            <ManifestInput
              fieldName={fieldName}
              manifest={manifest}
              original={original}
              setField={setField}
            />
          </FormGroup>
        )
      )
    }
    <FormGroup check>
      <Label check>
        <Input
          checked={manifest.hidden}
          onChange={(e) => setField('hidden', e.target.checked)}
          type="checkbox"
          valid={manifest.hidden === original.hidden}
        />{' '}
        Hidden
      </Label>
    </FormGroup>
  </Form>
);

RecordingEditForm.propTypes = {
  manifest: PropTypes.object,
  original: PropTypes.object,
  setField: PropTypes.func.isRequired
};

class RecordingEditModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      authcode: '',
      error: null,
      localManifest: null,
      isSaving: false
    };

    this.setManifestField = this.setManifestField.bind(this);
    this.saveManifest = this.saveManifest.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.recording && (!state.localManifest || props.recording.uuid !== state.localManifest.uuid)) {
      return {
        localManifest: { ...props.recording },
        isSaving: false,
        error: null
      };
    }
    return null;
  }

  setManifestField(field, value) {
    this.setState({
      localManifest: {
        ...this.state.localManifest,
        [field]: value
      }
    });
  }

  saveManifest() {
    const { session } = this.props;
    if (session) {
      this.setState(() => ({ isSaving: true }));
      session.call(
        config.RPC.UPDATE_RECORDING_MANIFEST,
        [
          this.state.localManifest,
          this.state.authcode
        ]
      ).then(
        () => {
          this.setState(() => ({ isSaving: false }) );
          this.props.onClose();
        }
      ).catch(
        (err) => {
          this.setState({
            isSaving: false,
            error: err
          });
        }
      );
    }
  }

  isDirty() {
    return !isEqual(this.state.localManifest, this.props.recording);
  }

  render() {
    const { recording, onClose } = this.props;
    return (
      <Modal
        className={`timing-modal timing-dialog h-100 d-flex flex-column justify-content-center my-0`}
        fade
        isOpen={!!recording}
        toggle={onClose}
      >
        <ModalHeader toggle={onClose}>
          Edit recording manifest
        </ModalHeader>
        <ModalBody>
          { recording ?
            <RecordingEditForm
              manifest={this.state.localManifest}
              original={recording}
              setField={this.setManifestField}
            />
            : null
          }
          <FormGroup className='mt-2'>
            <Label>
              Authkey:
            </Label>
            <Input
              onChange={(e) => this.setState({ authcode: e.target.value })}
              type='text'
              value={this.state.authcode}
            />
          </FormGroup>
          {
            this.state.error ?
              <p className='text-danger'>
                { this.state.error.args.join(';') }
              </p>
              : null
          }
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            disabled={!this.isDirty() || this.state.isSaving}
            onClick={this.saveManifest}
            outline
          >
            {
              this.state.isSaving ?
                <SVG
                  className='loading-spinner'
                  src={svgLogo}
                  uniquifyIDs={false}
                />
                : null
            }
            {
              this.state.isSaving ? 'Saving...' : 'Save'
            }
          </Button>
          <Button
            color='primary'
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

RecordingEditModal.propTypes = {
  onClose: PropTypes.func,
  recording: PropTypes.object,
  session: PropTypes.object
};

export default RecordingEditModal;
