import React from 'react';
import PropTypes from 'prop-types';

import { Loading, ServiceNotAvailable } from 'components/Modals';
import TimingServiceProvider from "./TimingServiceProvider";
import { subscriptions, withSubscription } from 'network';
import ServiceCountdown from 'components/ServiceCountdown';
import { withWindowsManager } from 'windowsManager';

import { SHOW_HIDDEN } from 'config/settings';

const ServiceLocator = ({ schedule, session, services, params, windowsManager, ...others }) => {

  if (services === undefined) {
    return <Loading />;
  }

  const { serviceClass } = params;

  const service = services ? services.find(
    (s) => s.serviceClass === serviceClass && (!s.hidden || SHOW_HIDDEN)
  ) : null;

  if (service) {

    return (
      <TimingServiceProvider
        params={{ serviceUUID: service.uuid }}
        services={services}
        session={session}
        {...others}
      />
    );

  }

  if (schedule) {
    const scheduledService = schedule.sort(
      (a, b) => a.startTime - b.startTime
    ).find(ss => ss.service === serviceClass);
    if (scheduledService) {
      return (
        <div className='service-countdown-wrapper'>
          <ServiceCountdown service={scheduledService} />
          <windowsManager.MainMenuOrCloseButton color='primary' />
        </div>
      );
    }
  }


  return <ServiceNotAvailable />;
};

ServiceLocator.propTypes = {
  params: PropTypes.object.isRequired,
  schedule: PropTypes.arrayOf(PropTypes.object),
  services: PropTypes.arrayOf(PropTypes.object),
  session: PropTypes.object,
  windowsManager: PropTypes.object.isRequired
};

export default withWindowsManager(
  withSubscription(
    ServiceLocator,
    [ subscriptions.SCHEDULE, subscriptions.SERVICES ]
  )
);
