import React from 'react';
import PropTypes from 'prop-types';

import FontAwesome from 'react-fontawesome';
import ReactSlider from 'react-slider';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { withWindowsManager } from 'windowsManager';
import CompactModeHint from './CompactModeHint';


const childrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node
]);

export const ToggleOption = ({ caption, enabled, icon, toggle }) => (
  <DropdownItem onClick={toggle}>
    <FontAwesome name={icon} />
    <span>{caption}</span>
    {
      enabled ? (
        <FontAwesome
          fixedWidth
          name='check'
        />
      )
        : null
    }
  </DropdownItem>
);

ToggleOption.propTypes = {
  caption: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired
};

ToggleOption.defaultProps = {
  icon: 'none'
};

const Span = ({ children }) => <span>{children}</span>;

Span.propTypes = {
  children: childrenPropType
};

const TimingScreenMenu = ({
  children,
  fontScale,
  isCompactMode,
  isFullscreen,
  setFontScale,
  setFullscreen,
  showAnimations,
  showBackgroundColours,
  toggleShowAnimations,
  toggleShowBackgroundColours,
  toggleColumnsDialog,
  toggleCompactMode,
  windowsManager
}) => {

  return (
    <React.Fragment>
      <UncontrolledDropdown
        id='timing-screen-menu'
        nav
      >
        <DropdownToggle
          caret
          color='link'
          title='Settings'
        >
          <FontAwesome
            name='cog'
          />
        </DropdownToggle>
        <DropdownMenu right>
          { children }
          <DropdownItem divider />
          <ToggleOption
            caption='Full screen'
            enabled={isFullscreen}
            icon='arrows-alt'
            toggle={() => setFullscreen(!isFullscreen)}
          />
          <ToggleOption
            caption='Blink on highlight'
            enabled={showAnimations}
            icon='lightbulb-o'
            toggle={toggleShowAnimations}
          />
          <ToggleOption
            caption='Background colours'
            enabled={showBackgroundColours}
            icon='eraser'
            toggle={toggleShowBackgroundColours}
          />
          <ToggleOption
            caption='Compact mode'
            enabled={isCompactMode}
            icon='compress'
            toggle={toggleCompactMode}
          />
          <DropdownItem header>
            <span>Font scale</span>
            <ReactSlider
              max={1.5}
              min={0.5}
              onChange={setFontScale}
              step={0.1}
              value={fontScale}
            />
            <span className="playback-position">{fontScale.toFixed(1)}</span>
          </DropdownItem>
          <DropdownItem onClick={toggleColumnsDialog}>
            <FontAwesome name='columns' />
            Configure columns...
          </DropdownItem>
          <DropdownItem divider />
          <windowsManager.MainMenuOrCloseButton
            className='dropdown-item'
            component={Span}
          />
        </DropdownMenu>
      </UncontrolledDropdown>
      <CompactModeHint
        isCompactMode={isCompactMode}
        target='timing-screen-menu'
      />
    </React.Fragment>
  );
};

TimingScreenMenu.propTypes = {
  children: childrenPropType,
  fontScale: PropTypes.number.isRequired,
  isCompactMode: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  setFontScale: PropTypes.func.isRequired,
  setFullscreen: PropTypes.func.isRequired,
  showAnimations: PropTypes.bool,
  showBackgroundColours: PropTypes.bool,
  toggleColumnsDialog: PropTypes.func.isRequired,
  toggleCompactMode: PropTypes.func.isRequired,
  toggleShowAnimations: PropTypes.func.isRequired,
  toggleShowBackgroundColours: PropTypes.func.isRequired,
  windowsManager: PropTypes.object.isRequired
};

export default withWindowsManager(TimingScreenMenu);
