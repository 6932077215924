import * as React from 'react';
import PropTypes from 'prop-types';

import { getDotpathValue, setDotpathValue } from 'utils/dotpath';
import { SHOW_HIDDEN } from '../config/settings';

export const PreferencesContext = React.createContext();

// TODO turn this into something more exciting backed by local storage
const INITIAL_PREFS = {
  behaviour: {
    'show_hidden': SHOW_HIDDEN
  }
};

export const PreferencesProvider = ({ children }) => {
  const [preferences, setPreferences] = React.useState(INITIAL_PREFS);

  const prefsValue = {
    set: (key, value) => setPreferences(setDotpathValue(preferences, key, value)),
    get: (key, fallback=null) => getDotpathValue(preferences, key, fallback)
  };

  return (
    <PreferencesContext.Provider value={prefsValue}>
      { children }
    </PreferencesContext.Provider>
  );
};

PreferencesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export const withPreferences = Component => props => (
  <PreferencesContext.Consumer>
    {
      (prefs) => (
        <Component
          preferences={prefs}
          {...props}
        />
      )
    }
  </PreferencesContext.Consumer>
);
