import React from 'react';
import PropTypes from 'prop-types';

import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';

export default class Spinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || 0
    };
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
  }

  increment() {
    this.onChange(this.state.value + 1);

  }

  decrement() {
    this.onChange(this.state.value - 1);
  }

  constrain(val) {
    const intVal = parseInt(val, 10) || 0;
    if (this.props.constrain) {
      return this.props.constrain(intVal);
    }
    return intVal;
  }

  onChange(newValue) {
    const constrainedValue = this.constrain(newValue);
    this.setState({ value: constrainedValue });
    if (this.props.onChange) {
      this.props.onChange(constrainedValue);
    }
  }

  render() {
    return (
      <InputGroup className='spinner'>
        <InputGroupAddon
          addonType='prepend'
          className='input-group-prepend'
        > { /* reactstrap doesn't yet support this in latest Bootstrap 4 beta */ }
          <Button
            color='primary'
            onClick={this.decrement}
          >-</Button>
        </InputGroupAddon>
        <Input
          onChange={(e) => this.onChange(parseInt(e.target.value, 10))}
          ref="spinner"
          type="text"
          value={this.state.value}
        />
        <InputGroupAddon
          addonType='append'
          className='input-group-append'
        >
          <Button
            color='primary'
            onClick={this.increment}
          >+</Button>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

Spinner.propTypes = {
  constrain: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.number
};
