import React from 'react';

import { PanelLoading } from 'components/Panel';

import { RELEASE_URL_BASE } from '../constants';
import DownloadCard from './DownloadCard';

const LATEST_RELEASE_INDEX = `${RELEASE_URL_BASE}/latest`;

const isApplicableFile = (f) => (
  f.type === 'file' &&
  f.name.match(/\.(exe|AppImage)$/)
);

const LatestDownloadList = () => {

  const [isLoading, setLoading] = React.useState(true);
  const [downloads, setDownloads] = React.useState([]);

  React.useEffect(
    () => {
      async function doLoad() {
        setLoading(true);
        const index = await fetch(LATEST_RELEASE_INDEX);
        const indexJson = await index.json();
        setDownloads(indexJson);
        setLoading(false);
      }
      doLoad();
    },
    []
  );

  if (isLoading) {
    return <PanelLoading />;
  }

  return (
    <div className='d-flex align-items-center'>
      {
        downloads.filter(isApplicableFile).map(
          (d, idx) => (
            <DownloadCard
              baseURL={LATEST_RELEASE_INDEX}
              download={d}
              key={idx}
            />
          )
        )
      }
      <a
        className='download-link'
        href="https://snapcraft.io/timing71"
        target='_blank'
      >
        <img
          alt="Get it from the Snap Store"
          src="https://snapcraft.io/static/images/badges/en/snap-store-black.svg"
        />
      </a>
    </div>
  );
};

export default LatestDownloadList;
