import React, { Suspense, lazy } from 'react';
import { IndexRoute, Route } from 'react-router';

import App from 'components/App.jsx';
import { AutobahnConnection } from 'network';
const AnalysisProvider = lazy(() => import(/* webpackChunkName: "analysis" */ 'containers/AnalysisProvider').catch(window.location.reload));
import TimingServiceProvider from 'containers/TimingServiceProvider';
const RemoteRecordingProvider = lazy(() => import(/* webpackChunkName: "recordings" */'containers/RemoteRecordingProvider').catch(window.location.reload));
const FileRecordingProvider = lazy(() => import(/* webpackChunkName: "recordings" */'containers/FileRecordingProvider').catch(window.location.reload));
import ServiceSelectionScreen from 'screens/ServiceSelectionScreen';
import ServiceLocator from 'containers/ServiceLocator';
import AllServicesScreen from 'screens/AllServicesScreen';
import RecordingListScreen from 'screens/RecordingListScreen';
const ReplayAnalysisProvider = lazy(() => import('./containers/ReplayAnalysisProvider'));
const FileAnalysisProvider = lazy(() => import('./containers/FileAnalysisProvider'));
import HelpScreen from './screens/HelpScreen';
import { Loading } from './components/Modals';
import DownloadScreen from './screens/DownloadScreen';

const suspenseful = Inner => props => (
  <Suspense fallback={<Loading />}>
    <Inner {...props} />
  </Suspense>
);

export const commonNetworkRoutes = (
  <React.Fragment>
    <Route
      component={TimingServiceProvider}
      path="timing/:serviceUUID"
    />
    <Route
      component={ServiceLocator}
      path="s/:serviceClass"
    />
    <Route
      component={suspenseful(AnalysisProvider)}
      path="analysis/:serviceUUID(/:analysisModule)"
    />
    <Route
      component={AllServicesScreen}
      path="services"
    />
    <Route
      component={RecordingListScreen}
      path="replays"
    />
  </React.Fragment>
);

export const commonLocalRoutes = (
  <React.Fragment>
    <Route
      component={suspenseful(RemoteRecordingProvider)}
      path="recording/:recordingUUID"
    />
    <Route
      component={suspenseful(RemoteRecordingProvider)}
      path="replay/:recordingUUID"
    />
    <Route
      component={suspenseful(FileRecordingProvider)}
      path="replay-file"
    />
    <Route
      component={suspenseful(ReplayAnalysisProvider)}
      path="ra/:recordingUUID(/:analysisModule)"
    />
    <Route
      component={suspenseful(FileAnalysisProvider)}
      path="analysis-file(/:analysisModule)"
    />
    <Route
      component={HelpScreen}
      path="help"
    />
    <Route
      component={DownloadScreen}
      path="download"
    />
  </React.Fragment>
);

const routes = (
  <Route component={App}>
    <Route
      component={AutobahnConnection}
      path='/'
    >
      <IndexRoute component={ServiceSelectionScreen} />
      { commonNetworkRoutes }
    </Route>
    { commonLocalRoutes }
  </Route>
);

export default routes;
