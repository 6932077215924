import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router';

import { subscriptions, withSubscription } from 'network';

import Copyright from '../components/Copyright';
import Panel from '../components/Panel';
import RecordingsListPanel from '../components/RecordingsListPanel';
import ServiceList from '../components/ServiceList';
import ScheduleList from '../components/ScheduleList';
import ServiceCountdown from "../components/ServiceCountdown";
import Version from '../components/Version';
import Header from '../components/Header';
import { withPreferences } from 'components/Preferences';

const getNextService = (schedule, showHidden) => _(schedule).reject((e) => e.hidden && !showHidden).sortBy((e) => e.startTime).head();

const ServiceSelectionScreen = ({ preferences, services, schedule, session }) => {

  const showHidden = preferences.get('behaviour.show_hidden', false);

  const nextService = getNextService(schedule, showHidden);

  const displayedServices = _.reject(services, (s) => s.hidden && !showHidden);

  return (
    <div id="service-selection-screen">
      <Header currentPage='live' />
      {
        displayedServices.length > 0 && services !== undefined ?
          <Panel
            className="service-list"
            header="Live now"
          >
            <ServiceList
              linkPart="timing"
              preferServiceClass
              services={displayedServices}
            />
          </Panel>
          :
          <Panel
            className="service-list"
            header="Next event"
            loading={services === undefined || schedule === undefined}
          >
            <ServiceCountdown service={nextService} />
          </Panel>
      }
      <RecordingsListPanel
        session={session}
        showHidden={showHidden}
      />
      <ScheduleList
        loading={schedule === undefined}
        schedule={schedule}
      />
      <div className="footer">
        <div>
          <Version />
          <Copyright />
        </div>
        <div>
          Check out <a href="https://beta.timing71.org/">Timing71 Beta</a> - the
          next generation of live timing and analysis, direct from timing
          providers to your browser.
        </div>
        {
          showHidden ? (
            <div className='text-right'>
              <Link to='/services'>
                <FontAwesome
                  name='key'
                />
              </Link>
            </div>
          )
            : null
        }
      </div>
    </div>
  );
};

ServiceSelectionScreen.propTypes = {
  preferences: PropTypes.shape({
    get: PropTypes.func.isRequired
  }),
  schedule: PropTypes.arrayOf(PropTypes.object),
  services: PropTypes.arrayOf(PropTypes.object),
  session: PropTypes.object
};

export default withSubscription(
  withPreferences(ServiceSelectionScreen),
  [
    subscriptions.SERVICES,
    subscriptions.SCHEDULE
  ]
);
