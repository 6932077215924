export const MPI = {
  CLOSE: 'close',
  CONNECT: 'connect',
  CONNECTION_STATE_CHANGE: 'connectionStateChange',
  ERROR: 'error',
  SET_SESSION: 'setSession',
  SUBSCRIBE: 'subscribe',
  SUBSCRIPTION_MESSAGE: 'subscriptionMessage',
  UNSUBSCRIBE: 'unsubscribe',
  CALL: 'call',
  CALL_RESULT: 'callResult',
  CALL_RESULT_REJECTED: 'callResultRejected'
};
