import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import Header from '../components/Header';
import Panel from '../components/Panel';
import { LatestDownloadList } from 'download';

const DownloadScreen = () => {
  return (
    <Container
      className="h-100 d-flex flex-column p-0"
      fluid
    >
      <Header currentPage='download' />
      <Container
        className="content"
        fluid
      >
        <Row>
          <Col>
            <Panel header='Timing71 desktop client'>
              <p>
                You can now access the Timing71 network on your desktop PC!
              </p>
              <ul>
                <li>Access all live services</li>
                <li>Replays and analysis, online or offline</li>
                <li>Run your own timing service plugins locally</li>
                <li>Directly connect to a remote timing service</li>
                <li>Available for Windows and Linux</li>
              </ul>
            </Panel>
            <Panel header='Latest downloads'>
              <LatestDownloadList />
            </Panel>
            <p>
              Please note: the desktop client, like this website, is a hobby
              project and, while I hope you will find it useful, I'm not able to
              guarantee support.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default DownloadScreen;
