import React from 'react';
import PropTypes from 'prop-types';

import { history } from 'config/settings';
import { NavLink as ReactstrapNavLink } from 'reactstrap';

const NavLink = ({ children, to, ...others }) => (
  <ReactstrapNavLink
    href={to}
    onClick={(evt) => { if (to) { history.push(to); evt.preventDefault(); }}}
    {...others}
  >
    {children}
  </ReactstrapNavLink>
);

NavLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  to: PropTypes.string
};

export default NavLink;
