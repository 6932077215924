import React from 'react';
import PropTypes from 'prop-types';

import { Nav, DropdownItem, NavItem, NavLink } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import Spinner from 'components/Spinner';
import TimingScreenMenu from '../components/TimingScreenMenu';
import { withWindowsManager } from 'windowsManager';
import { SHOW_HIDDEN } from 'config/settings';
import { isInIframe } from '../utils/embedding';

const TimingMenu = ({
  analysisRootPath,
  delay,
  service,
  setDelay,
  windowsManager,
  ...otherProps
}) => {

  const analysisURL = `${analysisRootPath}/${service.uuid}${SHOW_HIDDEN ? '?reveal=true' : ''}`;

  return (
    <Nav className="timing-menu controls">
      {
        service.hasAnalysis ? (
          <NavItem>
            <NavLink
              className="hidden-xs hidden-sm btn btn-link"
              onClick={() => windowsManager.navigate(analysisURL, true)}
              tag='button'
              target="analysis"
              title="Analysis"
            >
              <FontAwesome
                fixedWidth
                name='bar-chart'
              />
            </NavLink>
          </NavItem>
        ) : null
      }
      {
        isInIframe() ? (
          <NavItem>
            <NavLink
              href={window.location.href}
              target="_blank"
              title="Open in new tab"
            >
              <FontAwesome
                fixedWidth
                name='window-restore'
              />
            </NavLink>
          </NavItem>
        ) : null
      }
      <TimingScreenMenu {...otherProps}>
        <DropdownItem header>
          <span>
            Delay (secs):
            <Spinner
              constrain={(v) => Math.max(v, 0)}
              onChange={setDelay}
              value={delay}
            />
          </span>
        </DropdownItem>
        {
          service.hasAnalysis ? (
            <DropdownItem
              onClick={() => windowsManager.navigate(analysisURL, true)}
            >
              <FontAwesome name='bar-chart' /> Launch analysis
            </DropdownItem>
          ) : null
        }
      </TimingScreenMenu>
    </Nav>
  );
};

TimingMenu.propTypes = {
  analysisRootPath: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
  service: PropTypes.object.isRequired,
  setDelay: PropTypes.func.isRequired,
  windowsManager: PropTypes.object.isRequired
};

export default withWindowsManager(
  TimingMenu
);
