import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import FontAwesome from 'react-fontawesome';

import 'style/download.scss';

const downloadShape = PropTypes.shape({
  name: PropTypes.string.isRequired
});

const ICONS = {
  '.exe': 'windows',
  '.AppImage': 'linux'
};

const TYPE_TEXT = {
  '.exe': 'Windows (64-bit)',
  '.AppImage': 'Linux (AppImage)'
};

const parseFilename = (filename) => {

  const extension = filename.match(/\.[^.]+$/)[0] || '';
  const version = filename.match(/v[0-9]+\.[0-9]+\.[0-9]+(-[^.]+)?/)[0] || '';

  const name = filename.slice(0, -1 * (extension.length + version.length));

  return {
    extension,
    filename,
    icon: ICONS[extension],
    name,
    type: TYPE_TEXT[extension],
    version
  };
};

const DownloadIcon = ({ name }) => {
  return (
    <FontAwesome
      name={name}
      size='2x'
    />
  );
};

DownloadIcon.propTypes = {
  name: PropTypes.string.isRequired
};

const DownloadCard = ({ baseURL, download }) => {

  const parsed = parseFilename(download.name);

  return (
    <a
      className='download-link'
      href={`${baseURL}/${parsed.filename}`}
    >
      <div className='download-card'>
        <DownloadIcon name={parsed.icon} />
        <span className='name'>
          { parsed.name }
        </span>
        <div className='details'>
          <span>
            { parsed.type }
          </span>
          <span>
            {
              moment(download.mtime).format('YYYY-MM-DD')
            }
          </span>
          <span>
            { parsed.version }
          </span>
        </div>
      </div>
    </a>
  );
};

DownloadCard.propTypes = {
  baseURL: PropTypes.string,
  download: downloadShape
};

DownloadCard.defaultProps = {
  baseURL: ''
};

export default DownloadCard;
