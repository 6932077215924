import * as Sentry from '@sentry/browser';

import LocalConnectionManager from './localConnectionManager';
import SharedConnectionManager from './sharedConnectionManager';

import { isSyndicated } from 'utils/embedding';

export const getConnectionManager = () => {

  const syndicated = isSyndicated();

  Sentry.configureScope(
    (scope) => scope.setTag('syndicated', syndicated)
  );

  if (typeof(SharedWorker) === 'function' && !syndicated) {
    try {
      const scm = new SharedConnectionManager();  // Constructor will throw if shared worker fails to load

      Sentry.configureScope(
        (scope) => scope.setTag('isUsingSharedConnection', true)
      );

      console.log("Using shared connection (see chrome://inspect/#workers for details)");
      return scm;
    } catch (err) {
      // Fall back to local connection manager (below)
    }
  }

  Sentry.configureScope(
    (scope) => scope.setTag('isUsingSharedConnection', false)
  );

  return new LocalConnectionManager();

};

export { default as ConnectionStates } from './connectionStates';
export { MPI } from './constants';
