import ga from 'react-ga';
import * as Sentry from '@sentry/browser';
import queryString from 'query-string';
import { browserHistory, createMemoryHistory } from 'react-router';

// Include a handful of polyfills that we've seen errors about
import 'mdn-polyfills/String.prototype.padStart';
import 'array-flat-polyfill';

import packageJson from '../../package.json';
import config from "./config.json";

export default config;

export const APP_NAME = 'Timing71';

const qs = typeof(window) !== 'undefined' ? queryString.parse(window.location.search) : {};
export const SHOW_HIDDEN = (process.env.NODE_ENV === 'development') || qs.reveal === 'true';

export const SITE_ROOT_URL = 'https://www.timing71.org';
export const MASTER_URL = 'https://master.timing71.org';
export const RELAY_LIST_URL = `${MASTER_URL}/relays`;

export const RECORDINGS_URL = process.env.LIVETIMING_RECORDINGS_URL || 'https://replay.timing71.org';

const RAVEN_DSN = 'https://780d35654bfe464fa87bfd93230d89a2@sentry.io/177605';
const GA_TRACKING_ID = 'UA-78071221-1';

export const versionNumber = `${packageJson.version}${process.env.NODE_ENV === 'development' ? '-dev' : ''}`;

let _isElectron = false;
export let history = undefined;

const userAgent = typeof(navigator) !== 'undefined' ? navigator.userAgent.toLowerCase() : 'Electron-Main';
if (userAgent.indexOf(' electron/') > -1) {
  _isElectron = true;
  history = createMemoryHistory();
  if (qs.route) {
    history.push(qs.route);
  }
}
else {
  history = browserHistory;
}

export const ELECTRON_SET_MENU = qs.menu !== 'false';

export const configure = () => {
  if (process.env.NODE_ENV !== 'development' && !_isElectron) {
    Sentry.init({
      dsn: RAVEN_DSN,
      release: versionNumber,
      whitelistUrls: [
        'timing.71wytham.org.uk',
        'timing71.org'
      ]
    });

    if ('serviceWorker' in navigator) {
      window.addEventListener(
        'load',
        () => {
          navigator.serviceWorker.register('/service-worker.js').catch(
            console.error
          );
        }
      );
    }
  }
  else if (!_isElectron) {
    console.log("Not configuring Sentry client since NODE_ENV is 'development'");
  }

  if (typeof(window) !== 'undefined') { // ga needs window
    ga.initialize(GA_TRACKING_ID);

    ga.ga((t) => {
      const clientID = t.get('clientId');

      Sentry.configureScope((scope) => {
        scope.setUser({ id: clientID });
      });

    });
  }
};

export const AUTHORISED_REFERRERS = [
  /^https?:\/\/([^/]+\.)*timing.71wytham.org.uk/,
  /^https?:\/\/([^/]+\.)*timing71.org/,
  /^https?:\/\/([^/]+\.)*sportscarglobal.com/,
  /^https?:\/\/([^/]+\.)*theracingline.net/,
  /^https?:\/\/([^/]+\.)*racespot.tv/,
  /^http:\/\/localhost/,
  /^https:\/\/24h-dash.backfire.racing/,
  /^https?:\/\/([^/]+\.)*radio-show.co.uk/,
  /^https?:\/\/([^/]+\.)*radiolemans.com?/,
  /^https?:\/\/([^/]+\.)*radioshow.limited/
];

export const isElectron = () => _isElectron;
