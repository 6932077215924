import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TimingModal } from './Modals';

import { Button, Input, Label } from 'reactstrap';

const ColumnOption = ({ spec, hideColumn, showColumn, isHidden }) => (
  <li className={classNames('timing-column-option', { 'disabled': isHidden })}>
    <Label check>
      <Input
        checked={!isHidden}
        onChange={
          (e) => e.target.checked ? showColumn(spec[0]) : hideColumn(spec[0])
        }
        type='checkbox'
      />
      {spec[0].toUpperCase()}
      {spec[2] ? ` - ${spec[2]}` : null}
    </Label>
  </li>
);

ColumnOption.propTypes = {
  hideColumn: PropTypes.func.isRequired,
  isHidden: PropTypes.bool,
  showColumn: PropTypes.func.isRequired,
  spec: PropTypes.arrayOf(PropTypes.string).isRequired
};

class TimingColumnHider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: []
    };
    this.hideColumn = this.hideColumn.bind(this);
    this.showColumn = this.showColumn.bind(this);
  }

  hideColumn(col) {
    this.setState({
      hiddenColumns: [
        ...this.state.hiddenColumns,
        col
      ]
    });
  }

  showColumn(col) {
    this.setState({
      hiddenColumns: this.state.hiddenColumns.filter((s) => s !== col)
    });
  }

  render() {
    const { children, columnSpec, isShowingDialog, toggleDialog } = this.props;

    const childrenWithProps = React.Children.map(
      children,
      (child) => React.cloneElement(child, { hideColumns: this.state.hiddenColumns })
    );

    return (
      <React.Fragment>
        {childrenWithProps}
        <TimingModal
          className='timing-dialog'
          isOpen={isShowingDialog}
          title='Configure columns'
          toggle={toggleDialog}
        >
          <p>Select which columns to show. (Note: This preference is not currently saved when you reload the page.)</p>
          <ul className='columns-selector'>
            {
              columnSpec.map(
                (spec, idx) => (
                  <ColumnOption
                    hideColumn={this.hideColumn}
                    isHidden={this.state.hiddenColumns.includes(spec[0])}
                    key={idx}
                    showColumn={this.showColumn}
                    spec={spec}
                  />
                )
              )
            }
          </ul>
          <Button
            block
            color='primary'
            onClick={toggleDialog}
          >
            Close
          </Button>
        </TimingModal>
      </React.Fragment>
    );
  }

}

TimingColumnHider.propTypes = {
  children: PropTypes.node,
  columnSpec: PropTypes.arrayOf(PropTypes.array).isRequired,
  isShowingDialog: PropTypes.bool,
  toggleDialog: PropTypes.func.isRequired
};

export default TimingColumnHider;
