import React from 'react';
import PropTypes from 'prop-types';

import DocumentTitle from 'react-document-title';

import { APP_NAME } from 'config/settings';

const App = ({ children }) => (
  <DocumentTitle title={APP_NAME}>
    <div className="h-100">
      { children }
    </div>
  </DocumentTitle>
);

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default App;
