// Based on https://github.com/snakesilk/react-fullscreen/blob/master/src/index.jsx

import React, { Component } from "react";
import PropTypes from "prop-types";
import fscreen from "fscreen";

const isCurrentlyFullScreen = () => fscreen.fullscreenElement === document.body;

class FullScreen extends Component {
  constructor(props) {
    super(props);

    this.detectFullScreen = this.detectFullScreen.bind(this);
  }

  componentDidMount() {
    fscreen.addEventListener("fullscreenchange", this.detectFullScreen);
  }

  componentDidUpdate() {
    const enabled = isCurrentlyFullScreen();
    if (enabled && !this.props.enabled) {
      this.leaveFullScreen();
    } else if (!enabled && this.props.enabled) {
      const maybePromise = this.enterFullScreen();
      if (maybePromise) {
        maybePromise.catch(
          (err) => console.warn('Error attempting to enable fullscreen mode:', err)
        );
      }
    }
  }

  componentWillUnmount() {
    fscreen.removeEventListener("fullscreenchange", this.detectFullScreen);
    this.leaveFullScreen();
  }

  detectFullScreen() {
    if (this.props.onChange) {
      this.props.onChange(isCurrentlyFullScreen());
    }
  }

  enterFullScreen() {
    if (fscreen.fullscreenEnabled) {
      return fscreen.requestFullscreen(document.body);
    }
    return Promise.resolve();
  }

  leaveFullScreen() {
    if (fscreen.fullscreenEnabled && isCurrentlyFullScreen()) {
      return fscreen.exitFullscreen();
    }
    return Promise.resolve();
  }

  render() {
    const className = ["fullscreen"];
    if (this.props.enabled) {
      className.push("fullscreen-enabled");
    }

    return (
      <div
        className={className.join(" ")}
        style={
          this.props.enabled ? { height: "100%", width: "100%" } : undefined
        }
      >
        {this.props.children}
      </div>
    );
  }
}

FullScreen.propTypes = {
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func
};

FullScreen.defaultProps = {
  enabled: false
};

export default FullScreen;
