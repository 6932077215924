export default class TimingDelay {
  constructor(handleData) {
    this._handleData = handleData;
    this._delay = 0;
    this._messageQueue = [];

    this._interval = null;
    this._processMessageQueue = this._processMessageQueue.bind(this);
    this.delayMessage = this.delayMessage.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  setDelay(seconds) {
    this._delay = seconds;

    if (seconds > 0) {
      this._interval = setInterval(this._processMessageQueue, 1000);
    }
    else {
      this.cancel();
      this._processMessageQueue();
    }
  }

  cancel() {
    if (this._interval) {
      clearInterval(this._interval);
      this._interval = null;
    }
  }

  delayMessage(message) {
    if (this._delay === 0) {
      this._handleData(message);
    }
    else {
      const arrivedAt = Date.now();
      this._messageQueue.push([arrivedAt, message]);
    }
  }

  _processMessageQueue() {
    while (
      this._messageQueue.length > 0 &&
      (this._messageQueue[0][0] + (1000 * this._delay)) < Date.now()
    ) {
      const msg = this._messageQueue.shift();
      this._handleData(msg[1]);
    }
  }
}
