import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import moment from 'moment';
import { SHOW_HIDDEN } from 'config/settings';

import { Button } from 'reactstrap';
import Panel from './Panel';

const eventShape = PropTypes.shape({
  hidden: PropTypes.bool,
  name: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired
});

const ScheduleEntry = ({ event, isUTC }) => {
  const renderFunc = (raw) => {
    const local = moment.utc(raw * 1000);
    const formatString = "HH:mm";
    return (isUTC) ? local.format(formatString) : local.local().format(formatString);
  };

  return <li className={event.hidden ? 'hidden' : ''}><b>{renderFunc(event.startTime)}</b>: {event.name}</li>;
};

ScheduleEntry.propTypes = {
  event: eventShape,
  isUTC: PropTypes.bool
};

const ScheduleDay = ({ day, events, isUTC }) => {
  return (
    <div>
      <h3>{moment.unix(day).format("ddd Do MMMM")}</h3>
      <ul>
        {_(events).map((evt) => (
          <ScheduleEntry
            event={evt}
            isUTC={isUTC}
            key={evt.id}
          />
        )).value()}
      </ul>
    </div>

  );
};

ScheduleDay.propTypes = {
  day: PropTypes.number.isRequired,
  events: PropTypes.arrayOf(eventShape),
  isUTC: PropTypes.bool
};

const BetaFooter = () => (
  <div className='beta-footer'>
    <h4>Not listed here?</h4>
    Check out <a href="https://beta.timing71.org/">Timing71 Beta</a> - data direct from timing providers
    into your web browser. New features being added all the time!
  </div>
);

export default class ScheduleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isUTC: false };
  }

  render() {
    const { schedule } = this.props;
    const { isUTC } = this.state;
    if (schedule && schedule.length > 0) {

      const byDays = _(schedule).reject((e) => e.hidden && !SHOW_HIDDEN).sortBy((e) => e.startTime).groupBy(
        (evt) => isUTC ? moment.utc(evt.startTime * 1000).startOf("day").unix() : moment.utc(evt.startTime * 1000).local().startOf("day").unix()
      );

      const header = (
        <Fragment>
          Coming up
          <span className='float-right'>
            {
              isUTC ?
                <small>All times UTC</small>
                :
                <small>Times shown in your local timezone</small>
            }
            <Button
              className='ml-2'
              color='primary'
              onClick={() => {this.setState({ isUTC: !this.state.isUTC }); return false;}}
            >
              Show { isUTC ? 'local time' : 'UTC' }
            </Button>
          </span>
        </Fragment>
      );

      return (
        <Panel header={header}>
          <div className="schedule-list">
            {
              byDays.map((events, day) => (
                <ScheduleDay
                  day={parseInt(day, 10)}
                  events={events}
                  isUTC={isUTC}
                  key={day}
                />
              )).value()
            }
          </div>
          <BetaFooter />
        </Panel>
      );
    }
    else {
      return (
        <Panel
          header='Coming up'
          loading={schedule === undefined}
        >
          <p>No events scheduled</p>
          <BetaFooter />
        </Panel>
      );
    }
  }
}

ScheduleList.propTypes = {
  schedule: PropTypes.arrayOf(eventShape)
};
