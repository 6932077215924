import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const Paginator = ({ page, pageSize, itemCount, handleChangePage, maxPagins, urlPath }) => {
  const numPages = Math.ceil(itemCount / pageSize);

  if (itemCount === 0) {
    return null;
  }

  if (numPages === 1) {
    return <p className='pagination'>Showing all {itemCount} item{ itemCount !== 1 ? 's' : '' }.</p>;
  }

  let pagesToLink;
  if (numPages <= maxPagins) {
    pagesToLink = _.range(numPages);
  }
  else {
    // floating window of ten pages centred (where possible) on current
    const start = Math.max(Math.min(page - ~~(maxPagins / 2), numPages - maxPagins), 0);
    const end = Math.min(start + maxPagins, numPages);
    pagesToLink = _.range(start, end);
  }

  const Pagin = ({ className, disabled, head, next, p, previous, tail }) => (
    <PaginationItem
      active={page === p + 1}
      className={className}
      disabled={disabled}
    >
      <PaginationLink
        href={urlPath ? `${urlPath}/${p + 1}` : '#'}
        next={next}
        onClick={(e) => { e.preventDefault(); handleChangePage(p + 1); }}
        previous={previous}
      >
        { next || previous ? null : `${tail ? '...' : ''}${p + 1}${head ? '...' : ''}` }
      </PaginationLink>
    </PaginationItem>
  );

  Pagin.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    head: PropTypes.bool,
    next: PropTypes.bool,
    p: PropTypes.number.isRequired,
    previous: PropTypes.bool,
    tail: PropTypes.bool
  };

  return (
    <Pagination>
      <Pagin
        disabled={page === 1}
        p={page - 2}
        previous
      />
      {
        pagesToLink[0] === 0 ?
          null :
          <Pagin
            head
            p={0}
          />
      }
      {
        _.map(
          pagesToLink,
          (p, idx) => (
            <Pagin
              key={idx}
              p={p}
            />
          )
        )
      }
      {
        _.last(pagesToLink) === numPages - 1 ?
          null :
          <Pagin
            p={numPages - 1}
            tail
          />
      }
      <Pagin
        disabled={page === numPages}
        next
        p={page}
      />
    </Pagination>
  );
};

Paginator.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  itemCount: PropTypes.number.isRequired,
  maxPagins: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  urlPath: PropTypes.string
};

Paginator.defaultProps = {
  itemCount: 0,
  maxPagins: 10
};

export default Paginator;
