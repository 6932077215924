import React from 'react';
import PropTypes from 'prop-types';

import WallClock from './WallClock';
import { Button } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { isInIframe } from 'utils/embedding';

import logo16 from 'images/logo_16.png';

const ViaTiming71 = () => isInIframe() ? (
  <span>
    {' '}via{' '}
    <a
      href='https://www.timing71.org/'
      target='_blank'
    >
      <img
        className='mr-1'
        src={logo16}
      />
      Timing71
    </a>
  </span>
) : null;

const SourceAttribution = ({ external, isHiddenService, source }) => {
  if (source) {

    let textPart;

    if (source.length >= 2) {
      textPart = (
        <a
          dangerouslySetInnerHTML={{ __html: source[0] }}
          href={source[1]}
        />
      );
    }
    else {
      textPart = source[0];
    }

    return (
      <p>
        {
          isHiddenService ? (
            <FontAwesome
              className='mr-2'
              name='key'
            />
          ) : null
        }
        Data: {textPart}
        {
          !!external ?
            <React.Fragment>
              {' '}via{' '}
              <a href={external.url}>
                <FontAwesome
                  className='mr-1'
                  name='handshake-o'
                />
                {external.name}
              </a>
            </React.Fragment>
            :
            <ViaTiming71 />
        }
      </p>
    );
  }
  else {
    return null;
  }
};

const TrackDataFooter = ({ external, isHiddenService, messagesShowing, source, toggleMessages }) => (
  <div className="trackdata-footer d-flex justify-content-end">
    {
      messagesShowing ? (
        <SourceAttribution
          external={external}
          isHiddenService={isHiddenService}
          source={source}
        />
      )
        : <ViaTiming71 />
    }
    <WallClock />
    <Button
      color='primary'
      onClick={toggleMessages}
      size='sm'
      title={messagesShowing ? 'Hide messages' : 'Show messages'}
    >
      <FontAwesome name={messagesShowing ? 'compress' : 'expand'} />
    </Button>
  </div>
);

const sourceShape = PropTypes.arrayOf(PropTypes.string);
const externalShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  url: PropTypes.string
});

SourceAttribution.propTypes = {
  external: externalShape,
  isHiddenService: PropTypes.bool,
  source: sourceShape
};

TrackDataFooter.propTypes = {
  external: externalShape,
  isHiddenService: PropTypes.bool,
  messagesShowing: PropTypes.bool,
  source: sourceShape,
  toggleMessages: PropTypes.func.isRequired
};

export default TrackDataFooter;
