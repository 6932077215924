import React from 'react';
import PropTypes from 'prop-types';
import { history } from 'config/settings';

import { Link } from 'react-router';
import { Button } from 'reactstrap';

const webWindowsManager = {
  navigate(path, openNewWindow=false) {
    if (openNewWindow === true && typeof(window) !== 'undefined') {
      window.open(path);
    }
    else {
      history.push(path);
    }
  },

  MainMenuOrCloseButton({ className, component, innerClassName, ...props }) {
    const Component = component;
    return (
      <Link
        className={className}
        to="/"
      >
        <Component
          className={innerClassName}
          {...props}
        >
          Back to main menu
        </Component>
      </Link>
    );
  }
};

webWindowsManager.MainMenuOrCloseButton.propTypes = {
  className: PropTypes.string,
  component: PropTypes.func.isRequired,
  innerClassName: PropTypes.string
};

webWindowsManager.MainMenuOrCloseButton.defaultProps = {
  component: Button
};

export default webWindowsManager;
