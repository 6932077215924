import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';

import { withWindowsManager } from 'windowsManager';

import svgLogo from 'images/logo_no_text.svg';
import pngLogo from 'images/logo_128.png';

export const TimingModal = ({ className, children, isOpen, scrollOverflow, title, toggle }) => (
  <Modal
    className={`timing-modal ${className || ''} h-100 d-flex flex-column justify-content-center my-0`}
    fade
    isOpen={isOpen}
    toggle={toggle}
  >
    {
      title ?
        <ModalHeader toggle={toggle}>
          {title}
        </ModalHeader>
        : null
    }
    <ModalBody className={classNames('h-100', { 'overflow-auto': scrollOverflow })}>
      {children}
    </ModalBody>
  </Modal>
);

TimingModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  scrollOverflow: PropTypes.bool,
  title: PropTypes.string,
  toggle: PropTypes.func
};

TimingModal.defaultProps = {
  isOpen: true
};

const StaticLogo = () => (
  <img
    alt=""
    src={pngLogo}
  />
);

export const Loading = ({ caption, progress, ...props }) => (
  <TimingModal {...props}>
    <SVG
      className='loading-spinner'
      preload={<StaticLogo />}
      src={svgLogo}
      uniquifyIDs={false}
    >
      <StaticLogo />
    </SVG>
    <p>{caption}</p>
    { progress ? <p>{progress.toFixed(0)}%</p> : null }
  </TimingModal>
);

Loading.propTypes = {
  caption: PropTypes.string,
  progress: PropTypes.number
};

Loading.defaultProps = {
  caption: 'Loading...'
};

export const ServiceNotAvailable = withWindowsManager(
  ({ showMenuButton, windowsManager, ...props }) => (
    <TimingModal {...props}>
      <h3>Timing is not currently available</h3>
      <p>Please check back for future sessions.</p>
      {
        showMenuButton && (<windowsManager.MainMenuOrCloseButton color='primary' />)
      }
    </TimingModal>
  )
);

export const ErrorModal = ({ children, ...props }) => {
  return (
    <TimingModal
      className="error"
      {...props}
    >
      {children}
    </TimingModal>
  );
};

ErrorModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
