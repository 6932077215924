import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardBody, CardTitle } from 'reactstrap';
import SVG from 'react-inlinesvg';

import svgLogo from 'images/logo_no_text.svg';

export const PanelLoading = () => (
  <div className='panel-loading'>
    <SVG
      className='loading-spinner'
      src={svgLogo}
      uniquifyIDs={false}
    />
  </div>
)

const Panel = ({ className, children, header, loading, onClick }) => (
  <Card
    className={className}
    onClick={onClick}
  >
    <CardTitle tag='h5'>{header}</CardTitle>
    <CardBody>
      { loading ? <PanelLoading /> : children }
    </CardBody>
  </Card>
);

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  loading: PropTypes.bool,
  onClick: PropTypes.func
};

export default Panel;
