import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import ga from 'react-ga';
import { Router } from 'react-router';
import routes from 'routes';

import { configure, history } from 'config/settings';
import { isInIframe, isAuthorisedReferrer } from './utils/embedding';
import { ErrorModal } from 'components/Modals';
import { PreferencesProvider } from 'components/Preferences';

import ConnectionProvider from 'network/components/ConnectionProvider';
import { getConnectionManager } from 'network/connectionManager';
import { WindowsManagerProvider, webWindowsManager } from 'windowsManager';

configure();

function logPageView() {
  ga.pageview(window.location.pathname);
}

if (isInIframe() && !isAuthorisedReferrer()) {
  ReactDOM.render(
    <ErrorModal>
      <p>This site has not been given permission to embed the Timing71 service.</p>
      <p>
        <a
          href="https://www.timing71.org/"
          target="_top"
        >
          Go to Timing71
        </a>
      </p>
    </ErrorModal>,
    document.getElementById('app')
  );
}
else {
  ReactDOM.render(
    <ConnectionProvider connectionManager={getConnectionManager()}>
      <WindowsManagerProvider windowsManager={webWindowsManager}>
        <PreferencesProvider>
          <Router
            history={history}
            onUpdate={logPageView}
          >
            { routes }
          </Router>
        </PreferencesProvider>
      </WindowsManagerProvider>
    </ConnectionProvider>,
    document.getElementById('app')
  );
}
