import React from 'react';
import PropTypes from 'prop-types';

const ConnectionContext = React.createContext();

export const withConnectionManager = (Component) => {
  return function ConnectionComponent(props) {
    return (
      <ConnectionContext.Consumer>
        {
          (connectionManager) => (
            <Component
              {...props}
              connectionManager={connectionManager}
            />
          )
        }
      </ConnectionContext.Consumer>
    );
  };
};

const ConnectionProvider = ({ children, connectionManager }) => (
  <ConnectionContext.Provider value={connectionManager}>
    { children }
  </ConnectionContext.Provider>
);

ConnectionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  connectionManager: PropTypes.object.isRequired
};

export default ConnectionProvider;
