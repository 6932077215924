import React from 'react';
import PropTypes from 'prop-types';

import { Collapse, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import NavLink from './NavLink';

import logo from 'images/logo.png';
import Donate from './Donate';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { currentPage } = this.props;
    return (
      <div className="header">
        <Navbar
          dark
          expand='md'
        >
          <NavbarBrand>
            <img
              alt='Timing71: The live timing aggregator'
              id='header-logo'
              src={logo}
            />
          </NavbarBrand>
          <NavbarToggler
            className='mr-2'
            onClick={this.toggleNavbar}
          />
          <Collapse
            isOpen={this.state.expanded}
            navbar
          >
            <Nav
              className="justify-items-stretch w-100"
              navbar
              pills
            >
              <NavItem>
                <NavLink
                  className={currentPage === 'live' ? 'active' : ''}
                  to='/'
                >Live</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={currentPage === 'replays' ? 'active' : ''}
                  to='/replays'
                >Replays and analysis</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={currentPage === 'help' ? 'active' : ''}
                  to='/help'
                >Help</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={currentPage === 'download' ? 'active' : ''}
                  to='/download'
                >Download</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href='https://info.timing71.org/'
                  rel="noreferrer,noopener"
                  target="_blank"
                >
                  Developers
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href='https://www.twitter.com/timing_71'
                  rel="noreferrer,noopener"
                  target="_blank"
                >
                  <FontAwesome
                    className='mr-1'
                    name='twitter'
                  /> Twitter
                </NavLink>
              </NavItem>
              <NavItem style={{ flexGrow: 0, flexBasis: 'auto' }}>
                <Donate />
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

Header.propTypes = {
  currentPage: PropTypes.oneOf(['download', 'live', 'replays', 'help'])
};
