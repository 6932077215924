import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

const SyndicationEntry = ({ hasFinished, service: { external: { name, url }, uuid } }) => {

  const id = `tooltip_${uuid.replace(':', '_')}`;

  const icon = (
    <FontAwesome
      className='mr-1'
      name='handshake-o'
    />
  );

  const content = (
    <React.Fragment>
      {icon}
      {name}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <small id={id}>
        {
          url ? <a href={url}>{content}</a> : content
        }
      </small>
      <UncontrolledTooltip
        boundariesElement='window'
        placement='bottom'
        target={id}
      >
        This service { hasFinished ? 'was' : 'is being'} run on the Timing71 network by {name}.
      </UncontrolledTooltip>
    </React.Fragment>
  );
};

SyndicationEntry.propTypes = {
  hasFinished: PropTypes.bool,
  service: PropTypes.shape({
    external: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string
    }).isRequired,
    uuid: PropTypes.string.isRequired
  })
};

export default SyndicationEntry;
