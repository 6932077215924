import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router';
import config from '../config/settings';

import { Button, Row } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import Panel from './Panel';

import RecordingCard from './RecordingCard';

const RecordingEntryRow = ({ recordings }) => (
  <Row className='service-list'>
    { _.map(recordings, (rec) => (
      <RecordingCard
        key={rec.uuid}
        recording={rec}
      />
    )) }
  </Row>
);

const SeeAll = () => (
  <Link
    className='float-right'
    to='/replays'
  >
    <Button color='primary'>
      See all{ ' ' }
      <FontAwesome name='chevron-right' />
    </Button>
  </Link>
);

class RecordingsListPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      recordings: []
    };
  }

  componentDidMount() {
    this.fetchRecordings();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.session !== this.props.session) {
      this.fetchRecordings();
    }
  }

  fetchRecordings() {
    const { session, showHidden } = this.props;
    if (session) {
      this.setState({ loading: true });
      session.call(
        config.RPC.GET_RECORDINGS_PAGE,
        [
          1,
          null,
          showHidden
        ]
      ).then(
        (result) => {
          this.setState({
            loading: false,
            recordings: result.recordings
          });
        },
        (err) => {
          console.error(err);
          this.setState({ loading: false });
        }
      );
    }
  }

  render() {
    const { loading, recordings } = this.state;

    if (recordings.length === 0) {
      return (
        <Panel
          header="Recent events"
          loading={loading}
        >
          <p>No recordings available.</p>
        </Panel>
      );
    }

    const recordingGroups = _(this.state.recordings).slice(0, 8).chunk(2);

    return (
      <Panel
        header={<div>Recent events <SeeAll /></div>}
        loading={loading}
      >
        {recordingGroups.map((recs, idx) => (
          <RecordingEntryRow
            key={idx}
            recordings={recs}
          />
        )).value()}
      </Panel>
    );
  }
}

const recordingShape = PropTypes.shape({
  description: PropTypes.string.isRequired,
  duration: PropTypes.number,
  hidden: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['true', 'false'])]),
  name: PropTypes.string.isRequired,
  startTime: PropTypes.number,
  uuid: PropTypes.string.isRequired
});

RecordingEntryRow.propTypes = {
  recordings: PropTypes.arrayOf(recordingShape)
};

RecordingsListPanel.propTypes = {
  session: PropTypes.object,
  showHidden: PropTypes.bool
};

export default RecordingsListPanel;
