export const getDotpathValue = (store, key, fallback=null) => {
  let dottedPath;

  if (Array.isArray(key)) {
    dottedPath = key;
  }
  else {
    dottedPath = key.split('.');
  }

  if (dottedPath.length === 1) {
    return store[dottedPath[0]] || fallback;
  }
  else {
    const next = store[dottedPath[0]];
    if (next) {
      dottedPath.splice(0, 1);
      return getDotpathValue(next, dottedPath);
    }
    else {
      return fallback;
    }
  }
};

export const setDotpathValue = (store, key, value) => {
  let dottedPath;

  if (Array.isArray(key)) {
    dottedPath = key;
  }
  else {
    dottedPath = key.split('.');
  }

  if (dottedPath.length === 1) {
    if (Array.isArray(store)) {
      const result = [...store];
      result[dottedPath[0]] = value;
      return result;
    }
    else {
      return {
        ...store,
        [dottedPath[0]]: value
      };
    }
  }
  else {
    if (!store[dottedPath[0]]) {
      store[dottedPath[0]] = {};
    }
    const next = store[dottedPath[0]];
    dottedPath.splice(0, 1);
    return setDotpathValue(next, dottedPath, value);
  }
};
