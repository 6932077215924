import _ from 'lodash';
import { MPI } from './constants';

export default class ProxySession {
  constructor(id, send) {
    this.id = id;
    this.send = send;
    this.correlationID = 0;
    this.handlers = {};
    this.isOpen = true;

    this.subscribe = this.subscribe.bind(this);
  }

  subscribe(topic, handler, options) {
    const cid = this.correlationID++;
    this.handlers[cid] = handler;

    this.send(
      MPI.SUBSCRIBE,
      topic,
      options,
      cid
    );
    return Promise.resolve({ active: true, id: cid, topic: topic });
  }

  unsubscribe(sub) {
    const cid = sub.id;
    this.send(MPI.UNSUBSCRIBE, cid);
    delete this.handlers[cid];
    return Promise.resolve();
  }

  unsubscribeAll() {
    _.keys(this.handlers).forEach(
      (cid) => this.unsubscribe({ id: cid })
    );
  }

  call(...args) {
    const cid = this.correlationID++;
    const prom = new Promise(
      (resolve, reject) => {
        this.handlers[cid] = [resolve, reject];
        this.send(MPI.CALL, cid, ...args);
      }
    );
    return prom;
  }

  log(stuff) {
    console.log(stuff);
  }

  subscriptionMessage(correlationID, data, kwargs, details) {
    if (this.handlers[correlationID]) {
      this.handlers[correlationID](data, kwargs, details);
    }
  }

  callResult(correlationID, result) {
    if (this.handlers[correlationID]) {
      this.handlers[correlationID][0](result);
      delete this.handlers[correlationID];
    }
  }

  callResultRejected(correlationID, error) {
    if (this.handlers[correlationID]) {
      this.handlers[correlationID][1](error);
      delete this.handlers[correlationID];
    }
  }
}
