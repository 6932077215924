import React from 'react';
import PropTypes from 'prop-types';

import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table } from 'react-virtualized';

import { timestamp, classNameFromCategory } from 'utils/formats';


class MessagesInner extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getRow = this.getRow.bind(this);
    this.renderWrappingCell = this.renderWrappingCell.bind(this);

    this._cache = new CellMeasurerCache({
      defaultHeight: 28,
      fixedWidth: true,
      keyMapper: this._mapKey.bind(this),
      minHeight: 28
    });

    this._lastRenderedWidth = null;
    this._lastFontScale = null;
    this._lastTopMessageTimestamp = null;
  }

  getRow({ index }) {
    return this.props.messages[index];
  }

  _mapKey(rowIndex, columnIndex) {
    return `rowIndex-${this.props.messages[rowIndex][0]}-${columnIndex}`;
  }

  renderWrappingCell({ dataKey, parent, rowIndex }) {
    const { messages } = this.props;
    const content = messages[rowIndex][dataKey];

    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={0}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div
          style={{
            whiteSpace: 'normal',
            // We need to explicitly set a width on this element
            // https://github.com/bvaughn/react-virtualized/issues/1174
            width: this._lastRenderedWidth - 270,
            paddingBottom: 5
          }}
        >
          {content}
        </div>
      </CellMeasurer>
    );
  }

  render() {
    const { disableHeader, fontScale, height, messages, width } = this.props;

    if (
      this._lastRenderedWidth !== width ||
      this._lastFontScale !== fontScale || (
        messages.length > 0 && messages[0][0] !== this._lastTopMessageTimestamp)
    ) {
      this._cache.clearAll();
      this._lastRenderedWidth = width;
      this._lastFontScale = fontScale;
      this._lastTopMessageTimestamp = messages.length > 0 ? messages[0][0] : null;
    }

    return (
      <Table
        className='messagesTable'
        deferredMeasurementCache={this._cache}
        disableHeader={disableHeader}
        headerClassName='timing-table-header'
        headerHeight={28}
        height={height}
        overscanRowCount={0}
        rowClassName={({ index }) => index >= 0 ? `car_class_${classNameFromCategory(messages[index][1])} ${messages[index][3]}` : ''}
        rowCount={messages.length}
        rowGetter={this.getRow}
        rowHeight={this._cache.rowHeight}
        width={width}
      >
        <Column
          cellRenderer={({ cellData }) => timestamp(cellData)}
          className='time'
          dataKey={0}
          label='Time'
          width={100}
        />
        <Column
          className='category'
          dataKey={1}
          label='Class'
          width={150}
        />
        <Column
          cellRenderer={this.renderWrappingCell}
          className='text'
          dataKey={2}
          label='Message'
          width={width - 250}
        />
      </Table>
    );
  }
}

MessagesInner.propTypes = {
  disableHeader: PropTypes.bool,
  fontScale: PropTypes.number,
  height: PropTypes.number.isRequired,
  messages: PropTypes.arrayOf(PropTypes.array).isRequired,
  width: PropTypes.number.isRequired
};


const Messages = ({ disableHeader, messages }) => (
  <AutoSizer>
    {
      ({ height, width }) => (
        <MessagesInner
          disableHeader={disableHeader}
          height={height}
          messages={messages}
          width={width}
        />
      )
    }
  </AutoSizer>
);

export default Messages;

Messages.propTypes = {
  disableHeader: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.array)
};
