import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import {
  Button, Col, Card, CardTitle, CardBody, CardText
} from 'reactstrap';

import FontAwesome from 'react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import LinkContainer from 'components/LinkContainer';

import { datetime, timeWithHours } from 'utils/formats';
import { SHOW_HIDDEN } from 'config/settings';
import ServiceHeading from './ServiceHeading';
import SyndicationEntry from './SyndicationEntry';

const RecordingHiddenDetails = ({ editRecording, recording }) => !SHOW_HIDDEN ? null : (
  <div
    className='d-flex justify-content-between align-items-center mt-2'
    onClick={(e) => e.preventDefault()}
    title={recording.uuid}
  >
    <CopyToClipboard text={recording.filename}>
      <Button
        className='flex-fill mx-2'
        color='service'
      >
        <FontAwesome
          className='mr-2'
          name='file'
        />
        Copy filename
      </Button>
    </CopyToClipboard>
    <CopyToClipboard text={recording.uuid}>
      <Button
        className='flex-fill mx-2'
        color='service'
      >
        <FontAwesome
          className='mr-2'
          name='id-card'
        />
        Copy UUID
      </Button>
    </CopyToClipboard>
    <Button
      className='flex-fill mx-2'
      color='service'
      onClick={() => editRecording(recording)}
    >
      <FontAwesome
        className='mr-2'
        name='pencil'
      />
      Edit
    </Button>
  </div>
);

const RecordingCard = ({ editRecording, recording, showDetails, ...rest }) => {
  const toReplay = `/replay/${recording.uuid}`;
  const toAnalysis = `/ra/${recording.uuid}`;

  const classes = classNames(
    'service-panel',
    {
      'hidden': recording.hidden,
      'external': !!recording.external
    }
  );

  return (
    <Col
      className='card-group mb-4'
      {...rest}
    >
      <LinkContainer
        newWindow='electron'
        to={toReplay}
      >
        <Card className={classes}>
          <CardTitle tag='h5'>
            <ServiceHeading service={recording} />
          </CardTitle>
          <CardBody className='service-description'>
            <CardText tag='div'>
              <span>
                {recording.description}
              </span>
              {
                !!recording.external ?
                  <div>
                    <SyndicationEntry
                      hasFinished
                      service={recording}
                    />
                  </div>
                  : null
              }
            </CardText>
            {
              showDetails ?
                <React.Fragment>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>{datetime(recording.startTime * 1000)}</span>
                    <span>{timeWithHours(recording.duration)}</span>
                  </div>
                  <RecordingHiddenDetails
                    editRecording={editRecording}
                    recording={recording}
                  />
                </React.Fragment>
                : null
            }

            <div className="d-flex flex-column flex-md-row align-items-stretch mt-2">
              <LinkContainer
                className='flex-fill m-1'
                newWindow='electron'
                to={toAnalysis}
              >
                <Button
                  color='service'
                  disabled={recording.hasAnalysis !== true}
                >
                  <FontAwesome
                    className='mr-2'
                    name='bar-chart'
                  />
                  Analysis
                </Button>
              </LinkContainer>
              <LinkContainer
                className='flex-fill m-1'
                newWindow='electron'
                to={toReplay}
              >
                <Button
                  color='service'
                >
                  <FontAwesome
                    className='mr-2'
                    name='play'
                  />
                  Replay
                </Button>
              </LinkContainer>
            </div>
          </CardBody>
        </Card>
      </LinkContainer>
    </Col>
  );
};

const recordingShape = PropTypes.shape({
  description: PropTypes.string.isRequired,
  duration: PropTypes.number,
  external: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string
  }),
  filename: PropTypes.string.isRequired,
  hasAnalysis: PropTypes.bool,
  hidden: PropTypes.bool,
  name: PropTypes.string.isRequired,
  startTime: PropTypes.number,
  uuid: PropTypes.string.isRequired
});

RecordingCard.propTypes = {
  editRecording: PropTypes.func,
  recording: recordingShape.isRequired,
  showDetails: PropTypes.bool
};

RecordingHiddenDetails.propTypes = {
  editRecording: PropTypes.func,
  recording: recordingShape.isRequired
};

export default RecordingCard;
