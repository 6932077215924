import * as Sentry from '@sentry/browser';
import { AUTHORISED_REFERRERS } from "../config/settings";


export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const isAuthorisedReferrer = () => {
  if (document.referrer) {
    for (let i=0; i < AUTHORISED_REFERRERS.length; i++) {
      if (document.referrer.match(AUTHORISED_REFERRERS[i])) {
        return true;
      }
    }
    console.log("Not authorised:", document.referrer) //eslint-disable-line
    Sentry.captureException(
      new Error(`Unauthorised embedding detected at ${document.referrer}`)
    );
    return false;
  }
  return true;
};

export const isSyndicated = () => {
  for (let i=1; i < AUTHORISED_REFERRERS.length; i++) {
    if (document.referrer.match(AUTHORISED_REFERRERS[i])) {
      return true;
    }
  }
  return false;
};
